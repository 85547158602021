import React, { useState, useEffect } from 'react'
import { Button, Message, Table, Dimmer, Loader } from 'semantic-ui-react'
import ApiAgent from './ApiAgent';
import Page from './Page';
import { getPlannedVisitAppointmentDisplay, getAccountContact } from './ViewHelper';

function PlannedVisitAppointmentCancelForm({ account, plannedVisit, onCommit, onClose }) {
    const [error, setError] = useState("");
    const [page, setPage] = useState("confirm"); // confirm -> complete
    const [loading, setLoading] = useState(false);

    const accountContact = getAccountContact(account);

    const cancel = () => {
        setLoading(true);
        ApiAgent(account.auth_token).send(
            'PUT', `/planned_visits/${plannedVisit.id}/cancel`, null,
            () => {
                setError("");
                setPage("completed");
                setLoading(false);
            },
            (e) => {
                setError(e.message);
                setLoading(false);
            }
        );
    };

    useEffect(() => { window.scrollTo(0, 0) }, [error])

    return (
        <div>
            <Dimmer active={loading} inverted><Loader inverted>通信中..</Loader></Dimmer>
            <Message negative content={error} hidden={error === ""} />
            {(page === "confirm") &&
                <Page title="キャンセル確認" onReset={onClose}>
                    <p>以下の予約をキャンセルします。よろしいですか？</p>
                    <Table definition>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>お名前</Table.Cell>
                                <Table.Cell>{`${account.name} 様`}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>診療内容</Table.Cell>
                                <Table.Cell>{plannedVisit.name}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>予約日時</Table.Cell>
                                <Table.Cell>{getPlannedVisitAppointmentDisplay(plannedVisit)}</Table.Cell>
                            </Table.Row>
                            {plannedVisit.doctor &&
                                <Table.Row>
                                    <Table.Cell>医師</Table.Cell>
                                    <Table.Cell>{plannedVisit.doctor.name}</Table.Cell>
                                </Table.Row>
                            }
                            {accountContact &&
                                <Table.Row>
                                    <Table.Cell>予約案内の通知先</Table.Cell>
                                    <Table.Cell>{accountContact}</Table.Cell>
                                </Table.Row>
                            }
                        </Table.Body>
                    </Table>
                    <div style={{ textAlign: "center", margin: "1em 0" }}>
                        <Button negative onClick={() => cancel()}>キャンセル</Button>
                    </div>
                </Page>
            }
            {(page === "completed") &&
                <Page title="キャンセル完了">
                    <p style={{ textAlign: "center", margin: "2em 0" }}>予約をキャンセルしました</p>
                    <div style={{ textAlign: "center", margin: "2em 0" }}>
                        <Button onClick={() => onCommit()}>現在のご予約を表示</Button>
                    </div>
                </Page>
            }
        </div>
    );
}

export default PlannedVisitAppointmentCancelForm;
