import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { parseISO, format } from 'date-fns';
import { getSelfOrderPrescriptionDate } from './ViewHelper'
import { getApiPath } from './Utils'

function NewOrderAnnounce({ dealer, baseOrder, onClick }) {
    const prescriptionDate = getSelfOrderPrescriptionDate(baseOrder);
    return (
        <div style={{ margin: "0.5em 0", padding: "1em", border: "1px solid #CCCCCC" }}>
            <p>
                {format(prescriptionDate, "yyyy年M月d日")} に処方したコンタクトレンズの再注文ができます。
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <img src={getApiPath("/claves/dealer/logo.png")} alt={dealer.name} style={{ maxHeight: "2em" }} />
                <Button content="再注文" onClick={onClick} />
            </div>
        </div>
    );
}

function ProcessingOrderAnnounce({ dealer, processingOrder, onClick }) {
    return (
        <div style={{ margin: "0.5em 0", padding: "1em", border: "3px solid orange" }}>
            <p>
                ご注文商品の配送準備中です。（お届け予定日：{processingOrder.delivery?.delivery_date ? format(parseISO(processingOrder.delivery.delivery_date), "yyyy年M月d日") : "未定"}）<br />
                {processingOrder.delivery?.warning ?? ""}
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <img src={getApiPath("/claves/dealer/logo.png")} alt={dealer.name} style={{ maxHeight: "2em" }} />
                <Button content="注文内容" onClick={onClick} />
            </div>
        </div>
    );
}

function SelfOrderAnnounceList({ dealer, selfOrderStatusList, onAction }) {
    const [showAll, setShowAll] = useState(false);

    const emptyOrders = selfOrderStatusList.filter((selfOrderStatus) => !selfOrderStatus.processing_order).toReversed();
    const processingOrders = selfOrderStatusList.filter((selfOrderStatus) => selfOrderStatus.processing_order);

    const showingEmptyOrders = (showAll) ? emptyOrders : emptyOrders.slice(0, 3);

    return (
        <div>{(0 < selfOrderStatusList.length) &&
            <div style={{ margin: "2em 0" }}>
                <div style={{ display: "flex", justifyContent: "space-between", margin: "1em 0" }}>
                    <h3>お知らせ</h3>
                </div>
                <div>{
                    processingOrders.map((selfOrderStatus) =>
                        <ProcessingOrderAnnounce
                            key={selfOrderStatus.base_order.code}
                            dealer={dealer}
                            processingOrder={selfOrderStatus.processing_order}
                            onClick={() => { onAction("self_order_view", selfOrderStatus.processing_order) }}
                        />
                    )
                }</div>
                <div style={{ margin: "2em 0" }}>
                    <div>{
                        showingEmptyOrders.map((selfOrderStatus) =>
                            <NewOrderAnnounce
                                key={selfOrderStatus.base_order.code}
                                dealer={dealer}
                                baseOrder={selfOrderStatus.base_order}
                                onClick={() => onAction("self_order_form", selfOrderStatus.base_order)}
                            />
                        )
                    }</div>
                    {(emptyOrders.length !== showingEmptyOrders.length && !showAll) &&
                        <div style={{ display: "flex", justifyContent: "space-between", margin: "1em 0" }}>
                            <Button fluid basic size="mini" onClick={() => { setShowAll(true) }}>全ての処方コンタクトレンズを表示</Button>
                        </div>
                    }
                </div>
            </div>
        }</div>
    );
}

export default SelfOrderAnnounceList;
