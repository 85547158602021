import { format, parseISO, addMinutes } from 'date-fns';

export function getAppointmentPlan(plannedVisit) {
  if (plannedVisit.appointment_plan) {
    if (plannedVisit.appointment_plan.date) {
      const planDate = parseISO(plannedVisit.appointment_plan.date);
      return <div style={{ display: "inline-block" }}>
        <span>{plannedVisit.appointment_plan.title}</span>
        <span style={{fontSize: "0.8em", margin: "1em"}}>{`${format(planDate, `M月d日(${weekday[planDate.getDay()]})`)} 頃`}</span>
        </div>;
    } else {
      return <span>{plannedVisit.appointment_plan.title}</span>;
    }
  } else {
    return <span></span>;
  }
}

const weekday = ["日", "月", "火", "水", "木", "金", "土"];

function getDate(at) {
  return addMinutes(parseISO(at.date), at.time * 5);
}

export function getScheduleDateTime(at) {
  const d = getDate(at);
  if (at.fixed) {
    return `${getScheduleDate(at)}　${format(d, "H時mm分")}`;
  } else {
    return `${getScheduleDate(at)}　${at.time_slot}`;
  }
};

export function getScheduleDate(at) {
  const d = getDate(at);
  return format(d, `yyyy年M月d日(${weekday[d.getDay()]})`);
};

export function getPlannedVisitAppointmentDisplay(plannedVisit) {
  return `${getScheduleDateTime(plannedVisit.appointment.at)}　${plannedVisit.unplanned_appointment ? "（予約外）" : ""}`;
}

export function formatOrderItemKindName(kind) {
  switch (kind) {
    case "":
      return "";
    case "right":
      return "R";
    case "left":
      return "L";
    case "both":
      return "B";
    default:
      return kind ?? "";
  }
}

export function formatCurrency(value) {
  return Number(value).toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' });
}

export function formatLensData(bc, size, sph, cyl, ax, add) {
  const bcString = bc ? `BC: ${bc}` : null;
  const sizeString = size ? `Size: ${size}` : null;
  const sphString = sph ? `Sph: ${sph}` : null;
  const cylString = cyl ? `C: ${cyl}` : null;
  const axString = ax ? `Ax: ${ax}` : null;
  const addString = add ? `Add: ${add}` : null;
  return [bcString, sizeString, sphString, cylString, axString, addString].filter(x => x).join(", ");
}

export function getSelfOrderPrescriptionDate(order) {
  return parseISO(order.prescription_date ?? order.workflow.created_at);
}

export function getAccountContact(account) {
  if (account.app) {
    return "アプリ";
  } else if (account.masked_email) {
    return account.masked_email;
  } else if (account.masked_sms) {
    return account.masked_sms;
  } else {
    return null;
  }
}
