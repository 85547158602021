import React from 'react'
import { Table, Segment } from 'semantic-ui-react'
import { formatOrderItemKindName, formatCurrency, formatLensData } from './ViewHelper'
import { format, parseISO } from 'date-fns';

function SelfOrderView({ selfOrder }) {
    const orderDate = parseISO(selfOrder.workflow.ordered_at);
    return (
        <Segment>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "0.5em 0" }}>
                <div style={{fontWeight: "bold"}}>注文日：{format(orderDate, "yyyy年M月d日")}</div>
                <div style={{fontSize: "small"}}>注文番号：{selfOrder.code}</div>
            </div>
            <Table>
                <Table.Body>{
                    selfOrder.items.map((item) => {
                        return (
                            <Table.Row>
                                <Table.Cell width={2}>{formatOrderItemKindName(item.kind)}</Table.Cell>
                                <Table.Cell width={6}>
                                    <h5>{item.product.name}</h5>
                                    <p>{formatLensData(item.bc, item.size, item.sph, item.cyl, item.ax, item.add)}</p>
                                </Table.Cell>
                                <Table.Cell width={3} textAlign="right">
                                    {(item.product.available) ? `${formatCurrency(item.product.price)}／箱` : "取扱終了"}
                                </Table.Cell>
                                <Table.Cell width={3} textAlign="right">
                                    {`${item.amount}箱`}
                                </Table.Cell>
                                <Table.Cell width={3} textAlign="right">
                                    {formatCurrency(item.product.price * item.amount)}
                                </Table.Cell>
                            </Table.Row>
                        )
                    })
                }</Table.Body>
            </Table>
            <div style={{ textAlign: "right" }}>
                <div>
                    <span style={{ padding: "0 1em" }}>送料： {formatCurrency(selfOrder.postage_price)}</span>
                    <span style={{ padding: "0 1em", fontWeight: "bold" }} >合計： {formatCurrency(selfOrder.total_price)}</span>
                </div>
                <div>{selfOrder.invoice ? "領収済（クレジットカード）" : "未払"}</div>
            </div>
        </Segment>
    );
}

export default SelfOrderView;

