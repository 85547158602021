import React, { useState, useEffect } from "react";
import { Button, Message, Dimmer, Loader } from "semantic-ui-react";
import ApiAgent from "./ApiAgent";
import Page from "./Page";
import Question from "./Question";

const QuestionnaireForm = ({ account, visitId, questionnaireCode, entered, onCommit, onClose }) => {
  
  const apiAgent = ApiAgent(account.auth_token);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState("confirm");

  const [questionnaireState, setQuestionnaireState] = useState({
    current: -1,
    next: -1,
    prev: -1,
    enableSend: false,
    questions: [],
  });

  const getActiveQuestion = (questions) => {
    const activeQuestions = questions.map((q, index) => {
      return {
        index: index,
        question: q,
        isActive:
          q.previous_questions.length === 0 ||
          q.previous_questions.filter(
            (pq) =>
              questions.filter(
                (qq) =>
                  qq.code === pq.code && qq.answer.values.includes(pq.value)
              ).length > 0
          ).length > 0,
      };
    });
    //前の質問が非アクティブな場合、その質問を参照する質問も非アクティブ化
    //例）Q1←Q2、Q2←Q3の質問に依存している際に、Q1変更時にQ3を非アクティブにする
    //非アクティブとした質問の回答は登録時にクリアする（操作中にクリアすると誤操作で回答済みの質問が消えるため）
    activeQuestions
      .filter((s) => s.isActive && s.question.previous_questions.length > 0)
      .forEach((targetQuestion) => {
        targetQuestion.isActive =
          activeQuestions.filter(
            (ac) =>
              ac.isActive &&
              targetQuestion.question.previous_questions.filter(
                (pq) =>
                  ac.question.code === pq.code &&
                  ac.question.answer.values.includes(pq.value)
              ).length > 0
          ).length > 0;
      });

    return activeQuestions;
  };

  const updateQuestionnaireState = (questions, newCurrent) => {
    const activeQuestions = getActiveQuestion(questions);
    const newNext = activeQuestions.find(
      (s) => s.index > newCurrent && s.isActive
    );
    //findLastは2022年以降の最新ブラウザでないと動作しない使用しない。filter&slice(-1)[0]で代用。
    const newPrev = activeQuestions.filter(
      (s) => s.index < newCurrent && s.isActive
    );
    const newQuestionnaireState = {
      current: newCurrent,
      next: newNext === undefined ? -1 : newNext.index,
      prev: newPrev.length === 0 ? -1 : newPrev.slice(-1)[0].index,
      enableSend: newNext === undefined,
      questions: questions,
    };
    setQuestionnaireState(newQuestionnaireState);
  };

  const setCurrentValues = (values) => {
    const newQuestions = [...questionnaireState.questions];
    newQuestions[questionnaireState.current].answer.values = values;
    updateQuestionnaireState(newQuestions, questionnaireState.current);
  };

  const getQuestionnaire = () => {
    setLoading(true);
    setError("");
    const path = entered ? `/questionnaire/planned_visit/${visitId}` : `/questionnaire/${questionnaireCode}`;
    apiAgent.send(
      "GET",
      path,
      null,
      (x) => {
        updateQuestionnaireState(x.questions, 0);
        setLoading(false);
        setPage("confirm");
      },
      (e) => {
        setError(e.message);
        setLoading(false);
      }
    );
  };
  const putQuestionnaire = (questions) => {
    setLoading(true);
    setError("");
    apiAgent.send(
      "PUT",
      `/questionnaire/planned_visit/${visitId}`,
      {
        code: questionnaireCode,
        questions: questions
      },
      (x) => {
        setLoading(false);
        setPage("complete");
      },
      (e) => {
        setError(e.message);
        setLoading(false);
      }
    );
  };

  const goNextStep = () => {
    updateQuestionnaireState(
      questionnaireState.questions,
      questionnaireState.next
    );
  };

  const goPrevStep = () => {
    updateQuestionnaireState(
      questionnaireState.questions,
      questionnaireState.prev
    );
  };

  const sendQuestionnaire = () => {
    const newQuestions = [...questionnaireState.questions];
    const activeQuestions = getActiveQuestion(newQuestions);
    newQuestions
      .filter(
        (q) =>
          activeQuestions.filter(
            (aq) => !aq.isActive && aq.question.code === q.code
          ).length > 0
      )
      .forEach((targetQuestion) => {
        targetQuestion.answer.values = [];
      });
    putQuestionnaire(newQuestions);
  };

  useEffect(getQuestionnaire, []);

  return (
    <>
      <Dimmer active={loading} inverted>
        <Loader inverted>通信中..</Loader>
      </Dimmer>
      <Message negative content={error} hidden={error === ""} />
      {page === "confirm" && (
        <Page
          title="問診票入力"
          onReset={onClose}
          onRewind={questionnaireState.prev > -1 ? goPrevStep : null}
        >
          <div style={{ textAlign: "center", margin: "1em 0" }}>
            {questionnaireState.current > -1 && (
              <>
                <Question
                  question={
                    questionnaireState.questions[questionnaireState.current]
                  }
                  onValueChanged={setCurrentValues}
                />
                {questionnaireState.next > -1 && (
                  <Button onClick={goNextStep}>次へ</Button>
                )}
                {questionnaireState.enableSend && (
                  <Button positive onClick={sendQuestionnaire}>登録</Button>
                )}
              </>
            )}
          </div>
        </Page>
      )}
      {page === "complete" && (
        <Page title="問診票登録完了">
          <div style={{ textAlign: "center", margin: "0.5em" }}>
            <p>問診票を登録しました</p>
            <Button onClick={() => onCommit()}>現在のご予約を表示</Button>
          </div>
        </Page>
      )}
    </>
  );
};

export default QuestionnaireForm;
