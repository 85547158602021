import React from 'react'
import SelfOrderDealerHeader from './SelfOrderDealerHeader';
import SelfOrderView from './SelfOrderView';
import Page from './Page';

function SelfOrderDetail({ dealer, selfOrder, onClose }) {
    return (
        <Page title="注文内容" onRewind={onClose}>
            <SelfOrderDealerHeader dealer={dealer} />
            <SelfOrderView selfOrder={selfOrder} />
        </Page>
    );
}

export default SelfOrderDetail;