import React, { useState, useEffect } from 'react'
import { Button, Message, Table, Dimmer, Loader, Dropdown } from 'semantic-ui-react'
import { format, parseISO, differenceInDays } from 'date-fns';
import Calendar from './Calendar'
import ApiAgent from './ApiAgent';
import Page from './Page';
import { getScheduleDateTime, getAppointmentPlan, getAccountContact } from './ViewHelper'

function PlannedVisitAppointmentUpdateForm({ tenant, account, plannedVisit, specifiedAppointment, onCommit, onClose, onEntryQuestionnaire }) {
    const defaultMonth = () => {
        if (plannedVisit.appointment_plan?.date) {
            return parseISO(plannedVisit.appointment_plan.date);
        } else if (plannedVisit.appointment) {
            return parseISO(plannedVisit.appointment.at.date);
        } else {
            return new Date();
        }
    };

    const [error, setError] = useState("");
    const [page, setPage] = useState(specifiedAppointment ? "confirm" : "pick"); // pick -> confirm -> complete
    const [appointment, setAppointment] = useState(specifiedAppointment ? specifiedAppointment : null);
    const [candidates, setCandidates] = useState([]);
    const [month, setMonth] = useState(defaultMonth());
    const [selectedDate, setSelectedDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [doctor, setDoctor] = useState(plannedVisit.doctor);

    const candidateDays = [...new Set(candidates.map(x => x.at.date))].map(x => parseISO(x));
    const accountContact = getAccountContact(account);

    const appoint = () => {
        setLoading(true);
        ApiAgent(account.auth_token).send(
            'PUT', `/planned_visits/${plannedVisit.id}/appoint`, { appointment: appointment.id },
            () => {
                setError("");
                setPage("completed");
                setLoading(false);
            },
            (e) => {
                setError(e.message);
                setLoading(false);
            }
        );
    };

    const getCandidate = () => {
        setLoading(true);
        ApiAgent(account.auth_token).send(
            'GET', `/planned_visits/${plannedVisit.id}/candidates`,
            {
                month: format(month, "yyyy-MM"),
                doctor: doctor ? doctor.code : null
            },
            (x) => {
                setError("");
                setCandidates(x);
                setLoading(false);
            },
            (e) => {
                setError(e.message);
                setLoading(false);
            }
        );
    };

    useEffect(getCandidate, [account.auth_token, plannedVisit.id, month, doctor]);
    useEffect(() => { window.scrollTo(0, 0) }, [error])

    const candidateTimes = candidates.filter((candidate) => differenceInDays(parseISO(candidate.at.date), selectedDate) === 0);

    return (
        <div>
            <Dimmer active={loading} inverted><Loader inverted>通信中..</Loader></Dimmer>
            <Message negative content={error} hidden={error === ""} />
            {(page === "pick") &&
                <Page title="予約日時を選択" onReset={onClose}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div style={{ maxWidth: "30em" }}>
                            {(plannedVisit.appointment_plan) && 
                                <div>
                                    <h4>予約の目安</h4>
                                    <p style={{margin: "1em 0"}}>{getAppointmentPlan(plannedVisit)}</p>
                                </div>
                            }
                            {(tenant.can_select_appointment_doctor && doctor) &&
                                <div>
                                    <h4>医師</h4>
                                    {tenant.doctors.find(x => x.code === doctor.code) ?
                                        <Dropdown selection fluid
                                            value={doctor}
                                            options={tenant.doctors.map(x => ({ key: x.code, value: x, text: x.name }))}
                                            onChange={(e, { value }) => { setDoctor(value); }}
                                        />
                                        :
                                        <p>{doctor.name}</p>
                                    }
                                </div>
                            }
                            <h4>予約日時</h4>
                            <Calendar
                                month={month}
                                dates={candidateDays}
                                onSelectDate={(d) => { setSelectedDate(d); }}
                                onChangeMonth={(m) => { setSelectedDate(null); setMonth(m); }} />
                            {candidates.length === 0 ?
                                <Message style={{ textAlign: "center" }} warning>{format(month, 'M月')}は予約の空きがございません</Message>
                                :
                                <div style={{ margin: "1em 0" }}>
                                    {candidateTimes.length > 0 &&
                                        <Message info content="現在、予約可能な空き枠を表示しています。ご都合の良い時刻を選択してください。" />
                                    }
                                    {
                                        candidateTimes
                                            .map((candidate) => {
                                                return (
                                                    <Button
                                                        positive
                                                        style={{ margin: "0.5em 0", display: "flex", justifyContent: "space-between" }}
                                                        key={candidate.id}
                                                        fluid
                                                        onClick={() => { setAppointment(candidate); setPage("confirm"); }}>
                                                        <span>{getScheduleDateTime(candidate.at)}</span>
                                                        <span>{(candidate.doctor && !tenant.solo_doctor) ? candidate.doctor.name : ""}</span>
                                                    </Button>
                                                );
                                            })
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </Page>
            }
            {(page === "confirm") &&
                <Page title="予約内容の確認" onReset={onClose}>
                    <p>以下の内容で予約します。よろしいですか？</p>
                    <Table definition>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>お名前</Table.Cell>
                                <Table.Cell>{`${account.name} 様`}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>診療内容</Table.Cell>
                                <Table.Cell>{plannedVisit.name}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>予約日時</Table.Cell>
                                <Table.Cell>{getScheduleDateTime(appointment.at)}</Table.Cell>
                            </Table.Row>
                            {appointment?.doctor &&
                                <Table.Row>
                                    <Table.Cell>医師</Table.Cell>
                                    <Table.Cell>{appointment.doctor.name}</Table.Cell>
                                </Table.Row>
                            }
                            {accountContact &&
                                <Table.Row>
                                    <Table.Cell>予約案内の通知先</Table.Cell>
                                    <Table.Cell>{accountContact}</Table.Cell>
                                </Table.Row>
                            }
                        </Table.Body>
                    </Table>
                    <div style={{ textAlign: "center", padding: "0.5em" }}>
                        <Button positive onClick={() => appoint()}>予約する</Button>
                    </div>
                </Page>
            }
            {(page === "completed") &&
                <Page title="予約完了">
                    <p style={{ margin: "2em 0" }}>
                        予約が完了しました。ご来院を心よりお待ちしております。
                        {plannedVisit.questionnaire && !plannedVisit.questionnaire.entered &&
                            <><br/>事前に問診票を記入いただくと当日の診療がスムーズになります。</>
                        }
                    </p>
                    <div style={{ textAlign: "center", margin: "2em 0" }}>
                        {plannedVisit.questionnaire && !plannedVisit.questionnaire.entered &&
                            <Button positive onClick={() => onEntryQuestionnaire({code:plannedVisit.questionnaire.code, visitId:plannedVisit.id, entered:plannedVisit.questionnaire.entered})}>問診票を記入</Button>
                        }
                        <Button onClick={() => onCommit()}>現在のご予約を表示</Button>
                    </div>
                </Page>
            }
        </div>
    );
}

export default PlannedVisitAppointmentUpdateForm;
