import React, { useState, useEffect } from 'react'
import { Button, Form, Message, Dimmer, Loader } from 'semantic-ui-react'
import ApiAgent from './ApiAgent';
import { checkEmail } from './Utils'
import Page from './Page';

function EmailForm({ tenant, account, onCommit, onClose }) {
    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const [page, setPage] = useState("confirm"); // confirm -> complete
    const [loading, setLoading] = useState(false);

    const register = () => {
        setLoading(true);
        ApiAgent(account.auth_token).send(
            'PUT', `/profile/notification`, { email: email },
            () => {
                setError("");
                setPage("complete");
                setLoading(false);
            },
            (e) => {
                setError(e.message);
                setLoading(false);
            }
        );
    }

    useEffect(() => { window.scrollTo(0, 0) }, [error])

    return (
        <div>
            <Dimmer active={loading} inverted><Loader inverted>通信中..</Loader></Dimmer>
            <Message negative content={error} hidden={error === ""} />
            {(page === "confirm") &&
                <Page title="メールアドレスの設定" onRewind={onClose}>
                    <Form>
                        <Message info>
                            <Message.Content>
                                {account.masked_email ?
                                    <p>現在の設定: {account.masked_email} <Button size="tiny" onClick={() => { setEmail(""); register(); }}>削除</Button></p>
                                    :
                                    <p>メールアドレスが設定されていません</p>
                                }
                            </Message.Content>
                        </Message>

                        <Form.Field>
                            <label>新しいメールアドレス</label>
                            <Form.Input value={email} placeholder='sample@foo.bar' onChange={(e) => setEmail(e.target.value)}>
                                <input type="email" />
                            </Form.Input>
                        </Form.Field>
                        <div style={{ textAlign: "center", margin: "1em 0" }} >
                            <Form.Button positive disabled={email === "" || !checkEmail(email)} onClick={register}>登録</Form.Button>
                        </div>
                    </Form>
                </Page>
            }
            {(page === "complete") &&
                <Page title="設定完了">
                    <div style={{ textAlign: "center", margin: "1em 0" }} >
                        <p>メールアドレスの設定が完了しました</p>
                        <Button onClick={() => onCommit(email)}>現在のご予約を表示</Button>
                    </div>
                </Page>
            }
        </div>
    );
}

export default EmailForm;
