import React, { useState, useEffect } from 'react'
import { Message, Dropdown, Button, Dimmer, Loader, Modal } from 'semantic-ui-react'
import parse from 'html-react-parser'
import ApiAgent from './ApiAgent';
import Navigator from './Navigator';
import PlannedVisitAppointmentUpdateForm from './PlannedVisitAppointmentUpdateForm'
import PlannedVisitAppointmentCancelForm from './PlannedVisitAppointmentCancelForm'
import PasswordForm from './PasswordForm';
import EmailForm from './EmailForm';
import SmsForm from './SmsForm';
import AppointForm from './AppointForm';
import MobileAppBanner from './MobileAppBanner';
import { getScheduleDateTime, getAppointmentPlan, getPlannedVisitAppointmentDisplay, getAccountContact } from './ViewHelper';
import SelfOrderAnnounceList from './SelfOrderAnnounceList';
import SelfOrderForm from './SelfOrderForm';
import SelfOrderDetail from './SelfOrderDetail';
import SelfOrderHistory from './SelfOrderHistory';
import QuestionnaireForm from './QuestionnaireForm';

function PatientNavi({ tenant, defaultAccount, onClose }) {
    const [error, setError] = useState("");
    // top -> menu         -> create_appointment
    // top                 -> cancel_appointment 
    // top -> schedule     -> update_appointment
    // top -> change_password
    // top -> change_sms
    // top -> change_email
    // top -> self_order_form
    // top -> self_order_view
    // top -> self_order_history

    const [modal, setModal] = useState("");
    const [page, setPage] = useState("top");
    const [selectedLink, setSelectedLink] = useState(null);
    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const [selectedVisitTags, setSelectedVisitTags] = useState([]);
    const [targetPlannedVisit, setTargetPlannedVisit] = useState(null);
    const [plannedVisits, setPlannedVisits] = useState([]);
    const [loading, setLoading] = useState(false);
    const [specifiedAppointment, setSpecifiedAppointment] = useState(null);
    const [baseOrder, setBaseOrder] = useState();
    const [processingOrder, setProcessingOrder] = useState();
    const [dealer, setDealer] = useState();
    const [selfOrderStatusList, setSelfOrderStatusList] = useState([]);
    const [account, setAccount] = useState(defaultAccount);
    const [questionnaire, setQuestionnaire] = useState({
        code: "",
        visitId: "",
        entered: false
    });

    const reload = () => {
        const apiAgent = ApiAgent(account.auth_token);
        setLoading(true);
        setError("");
        apiAgent.send(
            "GET", "/token", null,
            (x) => {
                setAccount(x);
                apiAgent.send(
                    'GET', '/planned_visits', null,
                    (x) => {
                        setPlannedVisits(x);
                        if (tenant.capabilities.claves_self_order) {
                            apiAgent.send(
                                'GET', '/claves/self_order_status', null,
                                (res) => {
                                    setSelfOrderStatusList(res);
                                    setLoading(false);
                                },
                                (e) => {
                                    setError(e.message);
                                    setLoading(false);
                                }
                            );
                        } else {
                            setLoading(false);
                        }
                    },
                    (e) => {
                        setError(e.message);
                        setLoading(false);
                    }
                );
            },
            (e) => {
                setError(e.message);
                setLoading(false);
            }
        );
    };

    const goHome = () => {
        setPage("top");
        reload();
    }

    const entryQuestionnaire = ({code, visitId, entered}) => {
        setQuestionnaire({
            code: code,
            visitId: visitId,
            entered: entered
        });
        setPage("questionnaire_form");
    }

    useEffect(reload, [account.auth_token, tenant.capabilities.claves_self_order]);
    useEffect(() => { window.scrollTo(0, 0) }, [error])

    useEffect(() => {
        setLoading(true);
        if (tenant.capabilities.claves_self_order) {
            ApiAgent(account.auth_token).send(
                'GET', '/claves/dealer', null,
                (res) => {
                    setError("");
                    setDealer(res);
                    setLoading(false);
                },
                (e) => {
                    setError(e.message);
                    setLoading(false);
                }
            );
        }
    }, [account.auth_token, tenant.capabilities.claves_self_order]);

    const registerWaitingList = (visitId, subscribe) => {
        setLoading(true);
        ApiAgent(account.auth_token).send(
            subscribe ? 'POST' : 'DELETE',
            `/planned_visits/${visitId}/waiting_list`, null,
            () => {
                setError("");
                setModal(subscribe ? "キャンセル待ちにエントリーしました" : "キャンセル待ちのエントリーを解除しました");
                goHome();
            },
            (e) => {
                setError(e.message);
                setLoading(false);
            }
        );
    };

    const hasWaitingList = (plannedVisit) => {
        return plannedVisit.appointment && plannedVisit.appointment.waiting_list && !plannedVisit.locked;
    };

    const waitingListPanel = (plannedVisit) => (
        <div style={{ padding: "0.5em" }}>
            {(plannedVisit.appointment.alternative_appointments) ?
                <Message warning>
                    <h4>キャンセル待ち（エントリー中）</h4>
                    <p>{`他の患者様のキャンセル発生時に、${getAccountContact(account)} へ通知が届きます。`}</p>
                    {0 < plannedVisit.appointment.alternative_appointments.length &&
                        <div>
                            <h4>空き予約のご案内</h4>
                            <div>{
                                plannedVisit.appointment.alternative_appointments.map(alternativeAppointment => (
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "0.5em 0" }}>
                                        <span>{`${getScheduleDateTime(alternativeAppointment.at)}　${alternativeAppointment.doctor ? alternativeAppointment.doctor.name : ""}`}</span>
                                        {alternativeAppointment.free ?
                                            <Button color="orange" size="tiny" content="取得" onClick={() => {
                                                setTargetPlannedVisit(plannedVisit);
                                                setSpecifiedAppointment(alternativeAppointment);
                                                setPage("modify");
                                            }}
                                            />
                                            :
                                            <Button disabled size="tiny" content="終了" />
                                        }
                                    </div>
                                ))
                            }</div>
                        </div>
                    }
                    <div style={{ textAlign: "center", margin: "0.2em" }}>
                        <Button size="small" onClick={() => { registerWaitingList(plannedVisit.id, false) }} content="エントリー解除する" />
                    </div>
                </Message>
                :
                <Message info>
                    <h4>キャンセル待ちについて</h4>
                    <p>お早めの来院をご希望の方は、キャンセル待ちにエントリーできます。エントリーすると、他の患者様のキャンセル発生時に通知が届きます。</p>
                    {(account.app || account.masked_email) ?
                        <div style={{ textAlign: "center", margin: "0.2em" }}>
                            <Button
                                size="small" content="エントリーする"
                                onClick={() => {
                                    registerWaitingList(plannedVisit.id, true);
                                }}
                            />
                        </div>
                        :
                        <div style={{ textAlign: "center", margin: "0.2em" }}>
                            {tenant.capabilities.app ?
                                <div>
                                    <p>アプリからエントリーしてください</p>
                                    <MobileAppBanner tenant={tenant} />
                                    <p>（<button className='link-button' onClick={() => { setPage("change_email") }}>メールアドレスを登録</button>してエントリーすることもできます）</p>
                                </div>
                                :
                                <div>
                                    <p>メールアドレスを設定するとエントリーできます</p>
                                    <Button
                                        size="small" content="メールアドレスを設定"
                                        onClick={() => setPage("change_email")}
                                    />
                                </div>
                            }
                        </div>
                    }
                </Message>
            }
        </div>
    );

    const topView = (
        <div style={{ padding: "0.5em" }}>
            <Dimmer active={loading} inverted><Loader inverted>通信中..</Loader></Dimmer>
            <Modal onClose={() => setModal("")} open={modal !== ""} closeOnDimmerClick={false}>
                <Modal.Content>
                    <p>{modal}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button compact onClick={() => setModal("")} content="了解" />
                </Modal.Actions>
            </Modal>
            <Message negative content={error} hidden={error === ""} />
            <div style={{ textAlign: "right", clear: "both", padding: "1em 0" }}>
                <Dropdown item text={`[${account.code}] ${account.name} 様`}>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setPage("change_password")}>パスワードの変更</Dropdown.Item>
                        <Dropdown.Item onClick={() => setPage("change_email")}>メールアドレスの設定</Dropdown.Item>
                        {tenant.capabilities.sms &&
                            <Dropdown.Item onClick={() => setPage("change_sms")}>携帯番号（SMS）の設定</Dropdown.Item>
                        }
                        {tenant.capabilities.claves_self_order &&
                            <Dropdown.Item onClick={() => setPage("self_order_history")}>注文履歴</Dropdown.Item>
                        }
                        <Dropdown.Divider></Dropdown.Divider>
                        <Dropdown.Item text="ログアウト" onClick={onClose} />
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            {tenant.capabilities.claves_self_order &&
                <SelfOrderAnnounceList
                    dealer={dealer}
                    selfOrderStatusList={selfOrderStatusList}
                    onAction={(page, arg) => {
                        switch (page) {
                            case "self_order_form":
                                setBaseOrder(arg);
                                setPage("self_order_form");
                                break;
                            case "self_order_view":
                                setProcessingOrder(arg);
                                setPage("self_order_view");
                                break;
                            case "appointment":
                                setPage("create_appointment");
                                break;
                            default:
                                break;
                        }
                    }}
                />
            }

            {(!tenant.capabilities.app && !account.masked_email) &&
                <Message warning>
                    <p>メールアドレスを登録すると、予約のリマインド等の通知を受け取ることができます。</p>
                    <div style={{ textAlign: "center" }}>
                        <Button color="orange" size="tiny" onClick={() => setPage("change_email")}>登録する</Button>
                    </div>
                </Message>
            }

            <div style={{ display: "flex", justifyContent: "space-between", margin: "0.5em 0" }}>
                <h3>現在のご予約</h3>
            </div>

            {plannedVisits.length === 0 ?
                <Message info>
                    <p>現在のご予約はありません</p>
                </Message>
                :
                <div>{
                    plannedVisits.map((plannedVisit) => (
                        <div key={plannedVisit.id} style={{ margin: "0.5em 0", padding: "1em", border: "1px solid #CCCCCC" }}>
                            {plannedVisit.appointment ?
                                <h4>{getPlannedVisitAppointmentDisplay(plannedVisit)}</h4>
                                :
                                <Message error>予約をお取りください　{ getAppointmentPlan(plannedVisit) }</Message>
                            }
                            <div>{`${plannedVisit.name}　${(plannedVisit.appointment && plannedVisit.doctor) ? plannedVisit.doctor.name : ""}`}</div>
                            {plannedVisit.locked ?
                                <Message info>日時変更・キャンセルご希望の方は、 <a href={`tel:${tenant.phone_number.replace(/-/g, "")}`}>{tenant.phone_number}</a> までお電話ください</Message>
                                :
                                <div style={{ textAlign: "right", marginTop: "0.5em" }}>
                                    {plannedVisit.appointment ?
                                        <div>
                                            <Button style={{ margin: "0.2em" }} size="small" onClick={() => { setTargetPlannedVisit(plannedVisit); setPage("cancel") }} icon="delete" content="キャンセル" />
                                            <Button style={{ margin: "0.2em" }} size="small" onClick={() => { setTargetPlannedVisit(plannedVisit); setPage("modify") }} icon="edit" content="日時変更" />
                                        </div>
                                        :
                                        <Button style={{ margin: "0.2em" }} size="small" onClick={() => { setTargetPlannedVisit(plannedVisit); setPage("modify") }} icon="edit" content="予約する" positive />
                                    }
                                </div>
                            }
                            {!plannedVisit.locked && plannedVisit.appointment && plannedVisit.questionnaire &&
                                <div style={{ margin: "1em 0" }}>
                                    {plannedVisit.questionnaire.entered &&
                                        <div style={{ border: "1px solid #a9d5de", padding: "1em" }}>
                                            <p>
                                                問診票の記入ありがとうございます。<br />
                                                内容を変更したい場合は、以下のボタンから操作できます。
                                            </p>
                                            <div style={{textAlign:'center'}}>
                                                <Button style={{ margin: "0.2em" }} size="small" onClick={() => entryQuestionnaire({ code: plannedVisit.questionnaire.code, visitId: plannedVisit.id, entered: plannedVisit.questionnaire.entered })} icon="list" content="問診票を変更" />
                                            </div>
                                        </div>
                                    }
                                    {!plannedVisit.questionnaire.entered &&
                                        <div style={{ border: "1px solid #21ba45", padding: "1em" }}>
                                            <p>事前に問診票を記入いただくと当日の診療がスムーズになります。</p>
                                            <div style={{ textAlign: 'center' }}>
                                                <Button positive style={{ margin: "0.2em" }} size="small" onClick={() => entryQuestionnaire({ code: plannedVisit.questionnaire.code, visitId: plannedVisit.id , entered: plannedVisit.questionnaire.entered})} icon="list" content="問診票を記入" />
                                            </div>
                                        </div>
                                    }
                                </div> 
                            }
                            {plannedVisit.remarks &&
                                <div style={{ margin: "1em 0" }}>{
                                    parse(
                                        plannedVisit.remarks,
                                        {
                                            replace: domNode => {
                                                if (domNode.name === "input" && domNode.attribs?.type === "checkbox") {
                                                    return <input type="checkbox" checked readOnly />;
                                                }
                                            }
                                        }
                                    )
                                }</div>
                            }
                            {hasWaitingList(plannedVisit) && waitingListPanel(plannedVisit)}
                        </div>
                    ))
                }</div>
            }

            <Button
                fluid={true}
                    icon="add"
                    content={(plannedVisits.length === 0) ? "予約を取る" : "上記以外の予約を取る" }
                    onClick={() => {
                        setPage("menu");
                    }}
                />

        </div>
    );

    return (
        <div>
            {page === "top" &&
                topView
            }
            {page === "menu" &&
                <Navigator
                    tenant={tenant}
                    account={account}
                    item={{ kind: "menu", code: tenant.main_menu.patient }}
                    onCommit={(l, c, v) => { setSelectedLink(l); setSelectedCandidate(c); setSelectedVisitTags(v); setPage("appoint"); }}
                    onClose={goHome}/>
            }
            {page === "appoint" &&
                <AppointForm
                    account={account}
                    link={selectedLink}
                    candidate={selectedCandidate}
                    visitTags={selectedVisitTags}
                    onCommit={goHome}
                    onClose={goHome}
                    onEntryQuestionnaire={entryQuestionnaire} />
            }
            {page === "modify" &&
                <PlannedVisitAppointmentUpdateForm
                    tenant={tenant}
                    account={account}
                    plannedVisit={targetPlannedVisit}
                    specifiedAppointment={specifiedAppointment}
                    onCommit={goHome}
                    onClose={goHome}
                    onEntryQuestionnaire={entryQuestionnaire} />
            }
            {page === "cancel" &&
                <PlannedVisitAppointmentCancelForm
                    account={account}
                    plannedVisit={targetPlannedVisit}
                    onCommit={goHome}
                    onClose={goHome} />
            }
            {page === "change_password" &&
                <PasswordForm
                    account={account}
                    onCommit={goHome}
                    onClose={goHome} />
            }
            {page === "change_sms" &&
                <SmsForm
                    tenant={tenant}
                    account={account}
                    onCommit={goHome}
                    onClose={goHome} />
            }
            {page === "change_email" &&
                <EmailForm
                    tenant={tenant}
                    account={account}
                    onCommit={goHome}
                    onClose={goHome} />
            }
            {page === "self_order_form" &&
                <SelfOrderForm
                    account={account}
                    dealer={dealer}
                    baseOrder={baseOrder}
                    onClose={goHome}
                />
            }
            {page === "self_order_view" &&
                <SelfOrderDetail
                    dealer={dealer}
                    selfOrder={processingOrder}
                    onClose={goHome}
                />
            }
            {page === "self_order_history" &&
                <SelfOrderHistory
                    account={account}
                    dealer={dealer}
                    onClose={goHome}
                />
            }
            {page === "questionnaire_form" &&
                <QuestionnaireForm
                    account={account}
                    questionnaireCode={questionnaire.code}
                    visitId={questionnaire.visitId}
                    entered={questionnaire.entered}
                    onCommit={goHome}
                    onClose={goHome} 
                />
            }
        </div>
    );
}

export default PatientNavi;
