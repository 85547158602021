import React, { useState, useEffect } from 'react'
import { Button, Form, Message, Dimmer, Loader } from 'semantic-ui-react'
import ApiAgent from './ApiAgent';
import { checkSms } from './Utils'
import Page from './Page';

function SmsForm({ tenant, account, onCommit, onClose }) {
    const [error, setError] = useState("");
    const [sms, setSms] = useState("");
    const [page, setPage] = useState("confirm"); // confirm -> complete
    const [loading, setLoading] = useState(false);

    const register = () => {
        setLoading(true);
        ApiAgent(account.auth_token).send(
            'PUT', `/profile/notification`, { sms: sms },
            () => {
                setError("");
                setPage("complete");
                setLoading(false);
            },
            (e) => {
                setError(e.message);
                setLoading(false);
            }
        );
    }

    useEffect(() => { window.scrollTo(0, 0) }, [error])

    return (
        <div>
            <Dimmer active={loading} inverted><Loader inverted>通信中..</Loader></Dimmer>
            <Message negative content={error} hidden={error === ""} />
            {(page === "confirm") &&
                <Page title="携帯番号（SMS）の設定" onRewind={onClose}>
                    <Form>
                        <Message info>
                            <Message.Content>
                                {account.masked_sms ?
                                    <p>現在の設定: {account.masked_sms} <Button size="tiny" onClick={() => { setSms(""); register(); }} >削除</Button></p>
                                    :
                                    <p>携帯番号（SMS）が設定されていません</p>
                                }
                            </Message.Content>
                        </Message>

                        <Form.Field>
                            <label>新しい携帯番号（SMS）</label>
                            <Form.Input value={sms} placeholder='09011223344' onChange={(e) => setSms(e.target.value)}>
                                <input type="tel" />
                            </Form.Input>
                        </Form.Field>

                        <div style={{ textAlign: "center", margin: "1em 0" }} >
                            <Form.Button positive disabled={sms === "" || !checkSms(sms)} onClick={register}>登録</Form.Button>
                        </div>
                    </Form>
                </Page>
            }
            {(page === "complete") &&
                <Page title="設定完了">
                    <div style={{ textAlign: "center", margin: "1em 0" }} >
                        <p>携帯番号（SMS）の設定が完了しました</p>
                        <Button onClick={() => onCommit(sms)}>現在のご予約を表示</Button>
                    </div>
                </Page>
            }
        </div>
    );
}

export default SmsForm;
