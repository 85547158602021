import React from 'react'
import { Menu, Icon } from 'semantic-ui-react'

function NaviMenu({ tenant, menu, onSelectChildMenu, onSelectLink }) {
    return (
        <div>
            <Menu fluid vertical size="large">{
                menu.items.map((child) => {
                    if (child.kind === "menu") {
                        const childMenu = tenant.menus.find(x => x.code === child.code);
                        return (
                            <Menu.Item key={child.code} onClick={() => { onSelectChildMenu(childMenu) }}><Icon name="angle right" />{childMenu.name}</Menu.Item>
                        );
                    } else {
                        const childLink = tenant.links.find(x => x.code === child.code);
                        return (
                            <Menu.Item key={child.code} onClick={() => { onSelectLink(childLink) }}><Icon name="angle right" />{childLink.name}</Menu.Item>
                        );
                    }
                })
            } </Menu>

            <div style={{ margin: "1em 0" }}>
                <div dangerouslySetInnerHTML={{ __html: menu.remarks }}></div>
            </div>
        </div>
    );
}

export default NaviMenu;