import { getApiPath } from "./Utils";

function SelfOrderDealerHeader({ dealer }) {
    return (
        <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "0.5em" }}>
            <img src={getApiPath("/claves/dealer/logo.png")} alt={dealer.name} style={{ maxHeight: "2em" }} />
            <div style={{fontSize: "small"}}><a href={getApiPath("/claves/dealer/trade.html")} rel="noreferrer" target="_blank">特定商取引法に基づく表記</a> </div>
        </div>
    );
}

export default SelfOrderDealerHeader;