import React, { useEffect } from 'react'
import { Button } from 'semantic-ui-react'

function Page({ title, onRewind, onReset, children }) {
    useEffect(() => { window.scrollTo(0, 0); }, []);

    return (
        <div>
            <div style={{ zIndex: 1000, position: "sticky", top: 0, marginBottom: "0.5em", padding: "0.5em", background: "#F0F0F0" }}>
                <h3 style={{ margin: "0.25em", textAlign: "center" }}>{title}</h3>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {onRewind ? <Button size="mini" onClick={onRewind}>　戻る　</Button> : <span />} 
                    {onReset ? <Button size="mini" onClick={onReset}>やり直し</Button> : <span />}
                </div>
            </div>
            <div style={{ padding: "0.5em" }}>
                {children}
            </div>
        </div>
    );
}

export default Page;