
export function getApiPath(path) {
    const urlParams = new URLSearchParams(window.location.search);
    const explicitEndpoint = urlParams.get('endpoint');
    if (explicitEndpoint) {
        return explicitEndpoint + path;
    } else {
        const matches = window.location.pathname.match(/^\/([^/]+)\/.*$/);
        if (matches) {
            return `/${matches[1]}${path}`;
        } else {
            return path;
        }
    }
}

export function moveArrayElement(sequence, old_index, new_index) {
    let arr = sequence.concat();
    if (old_index < 0) {
        old_index = 0;
    } else if (arr.length <= old_index) {
        old_index = arr.length - 1;
    }
    if (new_index < 0) {
        new_index = 0;
    } else if (arr.length <= new_index) {
        new_index = arr.length - 1;
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
}

export function toNumber(value, rangeFrom, rangeTo, defaultValue) {
    if (value === "") {
        return null;
    } else {
        const parsed = parseInt(value, 10);
        if (isNaN(parsed) || parsed < rangeFrom || rangeTo < parsed) {
            return defaultValue;
        } else {
            return parsed;
        }
    }
}

export function checkSms(value) {
    const re = /^0[0-9]{10}$/;
    return re.test(value);
}

export function checkPhoneNumber(value) {
    const re = /^0[0-9]{9,10}$/;
    return re.test(value);
}

export function checkEmail(value) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value.toLowerCase());
}

export function checkZipCode(value) {
    const re = /^[0-9]{7}$/;
    return re.test(value);
}

export function checkName(value) {
    const re = /^[^\x20-\x7e]+$/;
    return re.test(value);
}

export function checkNameKana(value) {
    const re = /^[ァ-ヶー　]+$/;
    return re.test(value);
}

export function convertToHankakuAlphaNumeric(value) {
    return value.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
}
