import React, { useState } from 'react'
import { isToday, format, getDate, getDay, eachDayOfInterval, endOfWeek, eachWeekOfInterval, addMonths, subMonths, startOfMonth, endOfMonth, differenceInDays, getMonth } from 'date-fns';
import { Button, Label } from 'semantic-ui-react'

function Calendar({ month, dates, onSelectDate, onChangeMonth }) {
    const sundays = eachWeekOfInterval({ start: startOfMonth(month), end: endOfMonth(month) });
    const calendar = sundays.map(sunday => eachDayOfInterval({ start: sunday, end: endOfWeek(sunday) }));

    const [selectedDate, setSelectedDate] = useState(null);

    const sundayColor = "red";
    const saturdayColor = "steelblue";
    const getDayColor = (date) => {
        const day = getDay(date);
        if (day === 0) {
            return sundayColor;
        } else if (day === 6) {
            return saturdayColor;
        } else {
            return null;
        }
    }

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0.5em" }}>
                <Button onClick={() => { setSelectedDate(null); onChangeMonth(subMonths(month, 1)) }}>＜</Button>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>{format(month, 'y年M月')}</span>
                <Button onClick={() => { setSelectedDate(null); onChangeMonth(addMonths(month, 1)) }}>＞</Button>
            </div>
            <table style={{ width: "100%", tableLayout: "fixed" }}>
                <thead>
                    <tr>
                        <th style={{ color: sundayColor }}>日</th><th>月</th><th>火</th><th>水</th><th>木</th><th>金</th><th style={{ color: saturdayColor }}>土</th>
                    </tr>
                </thead>
                <tbody>
                    {calendar.map((weekRow, rowNum) => (
                        <tr key={rowNum}>
                            {weekRow.map(date => (
                                <td key={getDay(date)} style={{ padding: "0.5em", textAlign: "center" }}>
                                    {getMonth(month) === getMonth(date) &&
                                        <div>
                                            {dates.some(d => differenceInDays(d, date) === 0) ?
                                                <Label as="a" size="large" circular color={differenceInDays(selectedDate, date) === 0 ? "green" : "olive"} onClick={() => { setSelectedDate(date); onSelectDate(date); }}>{getDate(date)}</Label>
                                                :
                                                <span style={{ display: "inline-block", padding: "0.2em", color: getDayColor(date), backgroundColor: isToday(date) ? "yellow" : null }}>{getDate(date)}</span>
                                            }
                                        </div>
                                    }
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Calendar