import React, { useState, useEffect } from 'react'
import { Button, Table, Message, Dimmer, Loader, List } from 'semantic-ui-react'
import ApiAgent from './ApiAgent'
import Page from './Page';
import { getScheduleDateTime, getAccountContact } from './ViewHelper'

function AppointForm({ account, link, candidate, visitTags, onCommit, onClose, onEntryQuestionnaire }) {
    const [error, setError] = useState("");
    const [confirm, setConfirm] = useState(true);
    const [loading, setLoading] = useState(false);
    const [visitId, setVisitId] = useState("");

    const accountContact = getAccountContact(account);

    const appoint = () => {
        setLoading(true);
        ApiAgent(account.auth_token).send('POST', `/appointment_navi/${link.code}/appoint`,
            {
                appointments: candidate.ids,
                visit_tags: visitTags
            },
            (x) => {
                setError("");
                setVisitId(x.visits.length > 0 ? x.visits[0] : "");
                setConfirm(false);
                setLoading(false);
            },
            (e) => {
                setError(e.message);
                setLoading(false);
            }
        );
    };

    useEffect(() => { window.scrollTo(0, 0) }, [error])

    return (
        <div>
            <Dimmer active={loading} inverted><Loader inverted>通信中..</Loader></Dimmer>
            <Message negative content={error} hidden={error === ""} />
            {confirm ?
                <Page title="予約内容の確認" onReset={onClose}>
                    <p>以下の内容で予約します。よろしいですか？</p>
                    <Table definition>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>お名前</Table.Cell>
                                <Table.Cell>{`${account.name} 様`}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>診療内容</Table.Cell>
                                <Table.Cell>{link.name}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>予約日時</Table.Cell>
                                <Table.Cell>{getScheduleDateTime(candidate.at)}</Table.Cell>
                            </Table.Row>
                            {candidate.doctor &&
                                <Table.Row>
                                    <Table.Cell>医師</Table.Cell>
                                    <Table.Cell>{candidate.doctor.name}</Table.Cell>
                                </Table.Row>
                            }
                            {(1 < link.max_attendees) &&
                                <Table.Row>
                                    <Table.Cell>人数</Table.Cell>
                                    <Table.Cell>{`${candidate.ids.length} 名`}</Table.Cell>
                                </Table.Row>
                            }
                            {accountContact &&
                                <Table.Row>
                                    <Table.Cell>予約案内の通知先</Table.Cell>
                                    <Table.Cell>{accountContact}</Table.Cell>
                                </Table.Row>
                            }
                            {
                                link.visit_tag_selectors.map(selector =>
                                    <Table.Row key={selector.code}>
                                        <Table.Cell>{selector.title}</Table.Cell>
                                        <Table.Cell>
                                            <List bulleted>{
                                                selector.visit_tags.filter(t => visitTags.includes(t.code)).map(tag =>
                                                    <List.Item key={tag.code}>{tag.title}</List.Item>
                                                )
                                            }</List>
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
                    <div style={{ textAlign: "center", margin: "1em 0" }}>
                        <Button positive onClick={() => appoint()}>確定</Button>
                    </div>
                </Page>
                :
                <Page title="予約完了">
                    <p style={{ margin: "1em 0" }}>
                        予約が完了しました。ご来院を心よりお待ちしております。<br />
                        ご都合が悪くなった場合は、お早めに予約の変更・キャンセルをお願いします。
                        {link.questionnaire_template && visitId &&
                            <><br/>事前に問診票を記入いただくと当日の診療がスムーズになります。</>
                        }
                    </p>
                    
                    <div style={{ textAlign: "center", margin: "2em 0" }}>
                        {link.questionnaire_template && visitId &&
                            <Button positive onClick={() => onEntryQuestionnaire({code:link.questionnaire_template, visitId:visitId, entered:false})}>問診票を記入</Button>
                        }
                        <Button onClick={() => onCommit(account)}>現在のご予約を表示</Button>
                    </div>
                </Page>
            }
        </div>
    );
}

export default AppointForm;
