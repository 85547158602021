import React, { useState, useEffect } from 'react'
import { Button, Form, Message, Dimmer, Loader } from 'semantic-ui-react'
import ApiAgent from './ApiAgent';
import Page from './Page';

function PasswordForm({ account, onCommit, onClose }) {
    const [error, setError] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [page, setPage] = useState("confirm"); // confirm -> complete
    const [loading, setLoading] = useState(false);

    const checkInput = () => {
        // 英大文字、英小文字、数字を含む6文字以上（半角）
        let hasUppercase = false;
        let hasLowercase = false;
        let hasNumber = false;

        for (let i = 0; i < password1.length; i++) {
            let ch = password1.charCodeAt(i);
            if (ch < 32 || 126 < ch) {
                return false;
            } else if (48 <= ch && ch <= 57) {
                hasNumber = true;
            } else if (65 <= ch && ch <= 90) {
                hasUppercase = true;
            } else if (97 <= ch && ch <= 122) {
                hasLowercase = true;
            }
        }

        if (!hasNumber || !hasLowercase || !hasUppercase) {
            return false;
        }

        if (password1.length < 6) {
            return false;
        }

        if (password1 !== password2) {
            return false;
        }

        return true;
    }

    const changePassword = () => {
        setLoading(true);
        ApiAgent(account.auth_token).send(
            'PUT', `/profile/password`, { password: password1 },
            () => {
                setError("");
                setPage("complete");
                setLoading(false);
            },
            (e) => {
                setError(e.message);
                setLoading(false);
            }
        );
    };

    useEffect(() => { window.scrollTo(0, 0) }, [error])

    return (
        <div>
            <Dimmer active={loading} inverted><Loader inverted>通信中..</Loader></Dimmer>
            <Message negative content={error} hidden={error === ""} />
            {(page === "confirm") &&
                <Page title="パスワードの変更" onRewind={onClose}>
                    <Form>
                        <p>英大文字、英小文字、数字を含む6文字以上（半角）のパスワードを入力してください</p>
                        <Form.Input label='新しいパスワード' required type='password' value={password1} onChange={(e) => setPassword1(e.target.value)} />
                        <Form.Input label='新しいパスワード（確認）' required type='password' value={password2} onChange={(e) => setPassword2(e.target.value)} />
                        <div style={{ textAlign: "center", margin: "0.5em" }}>
                            <Button positive disabled={!checkInput()} onClick={() => changePassword()}>変更</Button>
                        </div>
                    </Form>
                </Page>
            }
            {(page === "complete") &&
                <Page title="設定完了">
                    <div style={{ textAlign: "center", margin: "0.5em" }}>
                        <p>パスワードを変更しました</p>
                        <Button onClick={() => onCommit()}>現在のご予約を表示</Button>
                    </div>
                </Page>
            }
        </div>
    );
}

export default PasswordForm;
