import React, { useState, useEffect, useMemo } from 'react'
import { Button, Message, Dropdown, Dimmer, Loader, Checkbox, List } from 'semantic-ui-react'
import parse from 'html-react-parser'
import { format, parseISO, differenceInDays } from 'date-fns';
import Calendar from './Calendar'
import ApiAgent from './ApiAgent'
import { getScheduleDateTime } from './ViewHelper'

function NaviLink({ tenant, account, link, onCommit, setShowRewind }) {
    const now = new Date();

    const [error, setError] = useState("");
    const [month, setMonth] = useState(now);
    const [doctor, setDoctor] = useState(tenant.doctors.find(x => x.code === link.doctor));
    const [numberOfAttendees, setNumberOfAttendee] = useState(1);
    const [candidates, setCandidates] = useState([]);
    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedVisitTags, setSelectedVisitTags] = useState(Array.from(link.visit_tag_selectors, s => []));
    const [selectedVisitTagsIndex, setSelectedVisitTagsIndex] = useState(0);

    const candidateDays = [...new Set(candidates.map(x => x.at.date))].map(x => parseISO(x));
    const selectedVisitTagsFlatten = useMemo(() => [].concat.apply([], selectedVisitTags), [selectedVisitTags]);
    const [totalChecksInRemarks, setTotalChecksInRemarks] = useState(0);
    const [checksInRemarks, setChecksInRemarks] = useState(new Set());

    const toggleCheckInRemarks = (name) => {
        if (name) {
            const newChecksInRemarks = new Set(checksInRemarks);
            if (newChecksInRemarks.has(name)) {
                newChecksInRemarks.delete(name);
            } else {
                newChecksInRemarks.add(name);
            }
            setChecksInRemarks(newChecksInRemarks);
        }
    };

    const getCandidate = () => {
        setLoading(true);
        ApiAgent(account ? account.auth_token : null).send(
            'GET', `/appointment_navi/${link.code}/candidates`,
            {
                month: format(month, "yyyy-MM"),
                doctor: doctor ? doctor.code : null,
                attendees: numberOfAttendees,
                visit_tags: selectedVisitTagsFlatten.join(",")
            },
            (x) => {
                setError("");
                setCandidates(x);
                setLoading(false);
            },
            (e) => {
                setError(e.message);
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        const totalChecks = document.querySelectorAll('.navilink-remarks input[type="checkbox"]').length;
        setTotalChecksInRemarks(totalChecks);
    }, [selectedCandidate]);
    useEffect(getCandidate, [account, link.code, month, doctor, numberOfAttendees, selectedVisitTagsFlatten]);
    useEffect(() => { window.scrollTo(0, 0) }, [error]);

    const visitTagSelector = () => {
        const selector = link.visit_tag_selectors[selectedVisitTagsIndex];
        const getCheck = (code) => {
            return selectedVisitTags[selectedVisitTagsIndex].includes(code);
        };
        const toggleCheck = (code) => {
            const newSelectedVisitTags = selectedVisitTags.map((selected, idx) => {
                if (selectedVisitTagsIndex !== idx) {
                    return selected;
                } else {
                    if (selector.multiple_answer) {
                        if (selected.includes(code)) {
                            return selected.filter(t => t !== code);
                        } else {
                            return selected.concat([code]);
                        }
                    } else {
                        if (selected.includes(code)) {
                            return selected;
                        } else {
                            return [code];
                        }
                    }
                }
            });
            setSelectedVisitTags(newSelectedVisitTags);
        };
        const visitTagRemarks = selector.visit_tags.filter(t => getCheck(t.code) && t.remarks !== "");
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "center", margin: "1em 0" }}>
                    <div style={{ maxWidth: "30em" }}>
                        <h4>{selector.title}</h4>
                        <div style={{ marginBottom: "0.5em" }}>
                            <div dangerouslySetInnerHTML={{ __html: selector.remarks }} />
                        </div>
                        <List>
                            {
                                selector.visit_tags.map((visitTag) => {
                                    return (
                                        <List.Item key={visitTag.code}><List.Content>
                                            <Checkbox
                                                radio={!selector.multiple_answer}
                                                label={visitTag.title}
                                                name={selector.code}
                                                checked={getCheck(visitTag.code)}
                                                onChange={() => toggleCheck(visitTag.code)}
                                            />
                                        </List.Content></List.Item>
                                    );
                                })
                            }
                        </List>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ maxWidth: "30em" }}>
                        {(0 < visitTagRemarks.length) &&
                            <Message warning visible={0 < visitTagRemarks.length}>
                                <Message.List>{
                                    visitTagRemarks.map(tag => <Message.Item key={tag.code}>{tag.remarks}</Message.Item>)
                                }</Message.List>
                            </Message>
                        }
                        <div style={{ textAlign: "center", margin: "1em 0" }} >
                            <Button positive disabled={selector.required && selectedVisitTags[selectedVisitTagsIndex].length === 0} onClick={() => setSelectedVisitTagsIndex(selectedVisitTagsIndex + 1)}>次へ</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const candidateTimes = candidates.filter((candidate) => differenceInDays(parseISO(candidate.at.date), selectedDate) === 0);
    const picker = (
        <div>
            <Dimmer active={loading} inverted><Loader inverted>通信中..</Loader></Dimmer>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ maxWidth: "30em" }}>
                    {(1 < link.max_attendees) &&
                        <div>
                            <h4>人数</h4>
                            <Dropdown selection fluid
                                value={numberOfAttendees}
                                options={[...Array(link.max_attendees).keys()].map(i => i + 1).map(n => ({ key: n, value: n, text: `${n}名` }))}
                                onChange={(e, { value }) => { setNumberOfAttendee(value); }}
                            />
                        </div>
                    }
                    {(tenant.can_select_appointment_doctor && doctor) &&
                        <div>
                            <h4>医師</h4>
                            {tenant.doctors.find(x => x.code === doctor.code) ?
                                <Dropdown selection fluid
                                    value={doctor}
                                    options={tenant.doctors.map(x => ({ key: x.code, value: x, text: x.name }))}
                                    onChange={(e, { value }) => { setDoctor(value); }}
                                />
                                :
                                <p>{doctor.name}</p>
                            }
                        </div>
                    }
                    <h4>予約日時</h4>
                    <Calendar
                        month={month}
                        dates={candidateDays}
                        onSelectDate={(d) => { setSelectedDate(d); }}
                        onChangeMonth={(m) => { setSelectedDate(null); setMonth(m); }} />
                    {candidates.length === 0 ?
                        <Message style={{ textAlign: "center" }} warning>{format(month, 'M月')}は空きがございません</Message>
                        :
                        <div style={{ margin: "1em 0" }}>
                            {candidateTimes.length > 0 &&
                                <Message info content="現在、予約可能な空き枠を表示しています。ご都合の良い時刻を選択してください。" />
                            }
                            {
                                candidateTimes.map((candidate) => {
                                        return (
                                            <Button
                                                positive
                                                style={{ margin: "0.5em 0", display: "flex", justifyContent: "space-between" }}
                                                key={candidate.ids[0]}
                                                fluid
                                                onClick={() => {
                                                    if (link.remarks === "") {
                                                        onCommit(link, candidate, selectedVisitTagsFlatten);
                                                    } else {
                                                        setSelectedCandidate(candidate);
                                                        setShowRewind(false)
                                                    }
                                                }}>
                                                <span>{getScheduleDateTime(candidate.at)}</span>
                                                <span>{(candidate.doctor && !tenant.solo_doctor) ? candidate.doctor.name : ""}</span>
                                            </Button>
                                        );
                                    })
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );

    const remarksChecker = (
        <div>
            <div className='navilink-remarks' style={{ marginBottom: "0.5em" }}>{
                parse(
                    link.remarks, 
                    { replace: domNode => {
                        if (domNode.name === "input" && domNode.attribs?.type === "checkbox") {                            
                            return <input type="checkbox" onChange={() => toggleCheckInRemarks(domNode.attribs?.name)} />;
                        }
                    }}
                )
            }</div>
            <div style={{ textAlign: "center", margin: "1em 0" }} >
                <Button positive disabled={checksInRemarks.size < totalChecksInRemarks} onClick={() => onCommit(link, selectedCandidate, selectedVisitTagsFlatten)}>了解</Button>
            </div>
        </div>
    );

    return (
        <div>
            <Message info content="お早めの受診をご希望の方は、予約完了後、キャンセル待ちにエントリーできます。（予約を取らずにキャンセル待ちのみをご利用いただくことはできません）" hidden={!link.waiting_list || !!selectedCandidate} />
            <Message warning content="以下の説明をお読みいただき、確認のチェックを入れてください" hidden={totalChecksInRemarks <= 0} />
            <Message negative content={error} hidden={error === ""} />
            {(selectedVisitTagsIndex < link.visit_tag_selectors.length) ? visitTagSelector() : (selectedCandidate ? remarksChecker : picker)}
        </div>
    );
}

export default NaviLink;