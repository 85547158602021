import React, { useState } from 'react'
import Navigator from './Navigator';
import ProspectForm from './ProspectForm';
import AppointForm from './AppointForm';
import QuestionnaireForm from './QuestionnaireForm';

function ProspectNavi({ tenant, item, onCommit, onClose }) {
    const [page, setPage] = useState("menu"); // menu -> auth -> appoint
    const [selectedLink, setSelectedLink] = useState(null);
    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const [selectedVisitTags, setSelectedVisitTags] = useState([]);
    const [account, setAccount] = useState(null);
    const [questionnaire, setQuestionnaire] = useState({
        code: "",
        visitId: "",
        entered: false
    });
    const entryQuestionnaire = ({code, visitId, entered}) => {
        setQuestionnaire({
            code: code,
            visitId: visitId,
            entered: entered
        });
        setPage("questionnaire_form");
    }
    const goHome = () => onCommit(account)

    return (
        <div>
            {(page === "menu") &&
                <Navigator
                    tenant={tenant}
                    account={null}
                    item={item}
                    onCommit={(l, c, v) => { setSelectedLink(l); setSelectedCandidate(c); setSelectedVisitTags(v); setPage("auth"); }}
                    onClose={onClose} />
            }
            {page === "auth" &&
                <ProspectForm
                    tenant={tenant}
                    onCommit={(c) => { setAccount(c); setPage("appoint"); }}
                    onClose={onClose} />
            }
            {page === "appoint" &&
                <AppointForm
                    account={account}
                    link={selectedLink}
                    candidate={selectedCandidate}
                    visitTags={selectedVisitTags}
                    onCommit={goHome}
                    onClose={onClose} 
                    onEntryQuestionnaire={entryQuestionnaire}/>
            }
            {page === "questionnaire_form" &&
                <QuestionnaireForm
                    account={account}
                    questionnaireCode={questionnaire.code}
                    visitId={questionnaire.visitId}
                    entered={questionnaire.entered}
                    onCommit={goHome}
                    onClose={goHome} 
                />
                }
        </div>
    );
}

export default ProspectNavi;
