import React, { useState } from 'react'
import NaviMenu from "./NaviMenu";
import NaviLink from "./NaviLink";
import Page from './Page';

function Navigator({ tenant, account, item, onCommit, onClose,  }) {
    const [histories, setHistories] = useState([]);
    const [currentItem, setCurrentItem] = useState(item);
    const [showRewind, setShowRewind] = useState(true);

    const currentMenu = tenant.menus.find(x => currentItem.kind === "menu" && x.code === currentItem.code);
    const currentLink = tenant.links.find(x => currentItem.kind === "link" && x.code === currentItem.code);

    const rewind = () => {
        setCurrentItem(histories.pop());
        setHistories(histories);
    }

    const forward = (nextItem) => {
        setHistories(histories.concat(currentItem));
        setCurrentItem(nextItem);
    }

    const onRewind = showRewind ? () => { (0 < histories.length) ? rewind() : onClose() } : null;

    return (
        <Page title={currentMenu ? currentMenu.name : (currentLink ? currentLink.name : "")} onReset={onClose} onRewind={onRewind}>
            {currentMenu &&
                <NaviMenu tenant={tenant} menu={currentMenu} onSelectChildMenu={x => forward({ kind: "menu", code: x.code })} onSelectLink={x => forward({ kind: "link", code: x.code })}></NaviMenu>
            }
            {currentLink &&
                <NaviLink tenant={tenant} account={account} link={currentLink} onCommit={onCommit} setShowRewind={setShowRewind} ></NaviLink>
            }
        </Page>
    );
}

export default Navigator;