import React, { useState, useEffect } from 'react'
import ApiAgent from './ApiAgent';
import { Message, Dropdown, Button, Dimmer, Loader, Form, Segment, Header, Table } from 'semantic-ui-react'
import Page from './Page';
import { formatOrderItemKindName, formatCurrency, formatLensData } from './ViewHelper'
import { checkZipCode, checkPhoneNumber, convertToHankakuAlphaNumeric, getApiPath, checkEmail } from './Utils'
import SelfOrderDealerHeader from './SelfOrderDealerHeader';

const deliveryTimeOptions = [
    { key: "any", value: "any", text: "（指定なし）" },
    { key: "am", value: "am", text: "午前中（〜12時）" },
    { key: "pm1", value: "pm1", text: "14時〜16時" },
    { key: "pm2", value: "pm2", text: "16時〜18時" },
    { key: "pm3", value: "pm3", text: "18時〜20時" },
    { key: "pm4", value: "pm4", text: "19時〜21時" },
];

const deliveryLockerOptions = [
    { key: "false", value: false, text: "なし" },
    { key: "true", value: true, text: "あり" },
];

function SelfOrderFormWelcomePage({ dealer, setPage, onClose }) {
    useEffect(() => { window.scrollTo(0, 0) }, []);

    return (
        <Page title="ご案内" onRewind={onClose}>
            <SelfOrderDealerHeader dealer={dealer} />

            <h4>コンタクトレンズのWeb再注文について</h4>
            <p>
                このフォームから、前回ご購入いただいたコンタクトレンズを再注文できます。ご来院は不要です。
            </p>

            <h4>注意事項</h4>
            <ul>
                <li>最後に当院で検査してから６ヶ月以上経過している場合は、Web再注文をご利用いただけません。再検査が必要ですので来院予約をお取りください。</li>
                <li>
                    Web再注文のお支払いにはクレジットカードが必要です。<br />
                    <img src={getApiPath("/claves/dealer/creditcard.png")} style={{ maxHeight: "1.5em" }} alt="クレジットカード 対応ブランド" />
                </li>
                <li>Web再注文のお支払いにNICポイントは使用できません。次回ご来院時に、受付スタッフへWeb再注文を利用した旨をお申し出ください。NICポイントを付与いたします。（<em>お申し出があるまで付与されませんのでご注意ください</em>）</li>
                <li>一部のコンタクトレンズはWeb再注文に対応しておりません。</li>
                <li>ご購入後の返品は、商品到着後30日以内で未開封のものに限ります。</li>
            </ul>

            <Button fluid primary onClick={() => setPage("notice")}>次へ</Button>
        </Page>
    );
}

function SelfOrderFormNoticePage({ dealer, setPage, onClose }) {
    const [warning, setWarning] = useState(false);

    useEffect(() => { window.scrollTo(0, 0) }, []);

    return (
        <Page title="利用条件確認" onRewind={() => setPage("welcome")} onReset={onClose}>
            <SelfOrderDealerHeader dealer={dealer} />

            <p style={{ margin: "2em 0" }}>最後に当院で検査してから６ヶ月以上経過していますか？</p>
            <div style={{ textAlign: "right" }} >
                <Button
                    primary
                    style={{ width: "10em" }}
                    content="いいえ"
                    onClick={() => {
                        setPage("question");
                    }}
                />
                <Button
                    primary
                    style={{ width: "10em" }}
                    content="はい"
                    onClick={() => {
                        setWarning(true);
                    }}
                />
            </div>

            <Message warning content="最後に当院で検査してから６ヶ月以上経過している場合は、Web再注文をご利用いただけません。再検査が必要ですので来院予約をお取りください。" hidden={!warning} />
        </Page>
    );
}

function SelfOrderFormQuestionPage({ dealer, setRemarks, setPage, onClose }) {
    const question = "現在装用中のコンタクトレンズに、見えにくさや違和感がありますか？";

    useEffect(() => { window.scrollTo(0, 0) }, []);

    return (
        <Page title="問診" onRewind={() => setPage("notice")} onReset={onClose}>
            <SelfOrderDealerHeader dealer={dealer} />

            <p style={{ margin: "2em 0" }}>{question}</p>
            <div style={{ textAlign: "right" }} >
                <Button
                    primary
                    style={{ width: "10em" }}
                    content="いいえ"
                    onClick={() => {
                        setRemarks(`[問診] ${question}：いいえ`);
                        setPage("order");
                    }}
                />
                <Button
                    primary
                    style={{ width: "10em" }}
                    content="はい"
                    onClick={() => {
                        setRemarks(`[問診] ${question}：はい`);
                        setPage("order");
                    }}
                />
            </div>
        </Page>
    );
}

function SelfOrderFormOrderPage({ account, dealer, baseOrder, remarks, newOrderData, newOrderEstimation, newEmail, setNewOrderData, setNewOrderEstimation, setNewEmail, setPage, onClose }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [estimation, setEstiation] = useState(newOrderEstimation);
    const [itemsAmount, setItemsAmount] = useState(newOrderData.items.map(x => x.amount));
    const [zipCode, setZipCode] = useState(newOrderData.destination.zip_code);
    const [zipCodeError, setZipCodeError] = useState();
    const [address, setAddress] = useState(newOrderData.destination.address);
    const [addressError, setAddressError] = useState();
    const [name, setName] = useState(newOrderData.destination.name);
    const [nameError, setNameError] = useState();
    const [phoneNumber, setPhoneNumber] = useState(newOrderData.destination.phone_number);
    const [phoneNumberError, setPhoneNumberError] = useState();
    const [deliveryTime, setDeliveryTime] = useState(newOrderData.destination.delivery_time);
    const [deliveryLocker, setDeliveryLocker] = useState(newOrderData.destination.delivery_locker);
    const [email, setEmail] = useState(newEmail);
    const [emailError, setEmailError] = useState();

    const normalizedZipCode = convertToHankakuAlphaNumeric(zipCode).replace(/[^0-9]/g, '');
    const normalizedPhoneNumber = convertToHankakuAlphaNumeric(phoneNumber).replace(/[^0-9]/g, '');

    const totalAmount = itemsAmount.reduce((a, x) => a + x, 0);
    const postageFreeBoundaries = Array.from(new Set(baseOrder.items.filter(x => x.product.postage_free_boundary).map(x => `${x.product.name}: ${x.product.postage_free_boundary}箱以上で送料無料`)));

    const checkInput = () => {
        var isValid = true;
        if (!checkZipCode(normalizedZipCode)) {
            setZipCodeError("郵便番号を入力してください");
            isValid = false;
        } else {
            setZipCodeError();
        }

        if (address === "") {
            setAddressError("住所を入力してください");
            isValid = false;
        } else {
            setAddressError();
        }

        if (name === "") {
            setNameError("氏名を入力してください");
            isValid = false;
        } else {
            setNameError();
        }

        if (!checkPhoneNumber(normalizedPhoneNumber)) {
            setPhoneNumberError("電話番号を入力してください");
            isValid = false;
        } else {
            setPhoneNumberError();
        }

        if (email && !checkEmail(email)) {
            setEmailError("メールアドレスの形式が正しくありません");
            isValid = false;
        } else {
            setEmailError();
        }

        return isValid;
    }

    useEffect(() => {
        const items = baseOrder.items.map((item, idx) => ({ kind: item.kind, product_code: item.product.code, product_unit_code: item.product_unit_code, amount: itemsAmount[idx] }));
        ApiAgent(account.auth_token).send(
            'POST', '/claves/self_order_estimation',
            { items: items },
            (res) => {
                setError("");
                setEstiation(res);
                setLoading(false);
            },
            (e) => {
                setError(e.message);
                setLoading(false);
            }
        );
    }, [account.auth_token, baseOrder, itemsAmount]);

    useEffect(() => { window.scrollTo(0, 0) }, [error]);

    return (
        <Page title="数量・お届け先の入力" onRewind={() => setPage("question")} onReset={onClose}>
            <SelfOrderDealerHeader dealer={dealer} />
            <Dimmer active={loading} inverted><Loader inverted>通信中..</Loader></Dimmer>
            <Message negative content={error} hidden={error === ""} />
            <Form>
                <Segment>
                    <Header as="h5">商品</Header>
                    <Message info>
                        <Message.List>
                            <Message.Item>数量を選択してください（最大６ヶ月分）</Message.Item>
                            {postageFreeBoundaries.map((x, idx) => <Message.Item key={idx}>{x}</Message.Item>)}
                        </Message.List>
                    </Message>
                    <Table basic>
                        <Table.Body>{
                            baseOrder.items.map((item, idx) => {
                                const amount = itemsAmount[idx];
                                const amountOptions = [...Array(item.product.max_amount + 1)].map((_, j) => ({ key: j, value: j, text: `${j}箱` }));
                                return (
                                    <Table.Row>
                                        <Table.Cell width={2}>{formatOrderItemKindName(item.kind)}</Table.Cell>
                                        <Table.Cell width={5}>
                                            <h5>{item.product.name}</h5>
                                            <p>{formatLensData(item.bc, item.size, item.sph, item.cyl, item.ax, item.add)}</p>
                                        </Table.Cell>
                                        <Table.Cell width={3} textAlign="right">
                                            <div>{(item.product.available) ? `${formatCurrency(item.product.price)}／箱` : "取扱終了"}</div>
                                        </Table.Cell>
                                        <Table.Cell width={3} textAlign="right">
                                            <Dropdown selection compact
                                                disabled={!item.product.available}
                                                value={amount}
                                                options={amountOptions}
                                                onChange={(e, { value }) => { setItemsAmount(itemsAmount.map((x, i) => (i === idx) ? value : x)) }}
                                            />
                                        </Table.Cell>
                                        <Table.Cell width={3} textAlign="right">
                                            {formatCurrency(item.product.price * amount)}
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })
                        }</Table.Body>
                    </Table>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <span style={{ padding: "0 1em" }}>送料： {estimation ? formatCurrency(estimation.postage_price) : "--"}</span>
                            <span style={{ padding: "0 1em", fontWeight: "bold" }} >合計： {estimation ? formatCurrency(estimation.total_price) : "--"}</span>
                        </div>
                    </div>
                </Segment>
                <Segment>
                    <Header as="h5">お届け先</Header>
                    <Form.Input
                        required
                        label="郵便番号"
                        placeholder="4600001"
                        value={zipCode}
                        error={zipCodeError}
                        onChange={(e, { value }) => setZipCode(value)}
                    />
                    <Form.Input
                        required
                        label="住所"
                        placeholder="愛知県名古屋市中区三の丸1-2-3 ABCマンション101"
                        value={address}
                        error={addressError}
                        onChange={(e, { value }) => setAddress(value)}
                    />
                    <Form.Input
                        required
                        label="氏名"
                        placeholder="佐藤　涼子"
                        value={name}
                        error={nameError}
                        onChange={(e, { value }) => setName(value)}
                    />
                    <Form.Input
                        required
                        label="電話番号"
                        placeholder="09012345678"
                        value={phoneNumber}
                        error={phoneNumberError}
                        onChange={(e, { value }) => setPhoneNumber(value)}
                    >
                        <input type="tel" />
                    </Form.Input>
                    <Form.Dropdown
                        selection
                        label="希望時間帯"
                        value={deliveryTime}
                        options={deliveryTimeOptions}
                        onChange={(e, { value }) => setDeliveryTime(value)}
                    />
                    <Form.Dropdown
                        selection
                        label="宅配ボックス"
                        value={deliveryLocker}
                        options={deliveryLockerOptions}
                        onChange={(e, { value }) => setDeliveryLocker(value)}
                    />
                </Segment>
                <Segment>
                    <Header as="h5">お届け予定日の通知先</Header>
                    {account.masked_email ?
                        <div>{account.masked_email}</div>
                        :
                        <Form.Input
                            label="メールアドレス"
                            placeholder='sample@foo.bar'
                            value={email}
                            error={emailError}
                            onChange={(e) => setEmail(e.target.value)}>
                            <input type="email" />
                        </Form.Input>
                    }
                </Segment>
                <Button
                    primary
                    fluid
                    disabled={!estimation || totalAmount === 0}
                    content="注文内容を確認"
                    onClick={() => {
                        if (checkInput()) {
                            const newOrderData = {
                                base_order_code: baseOrder.code,
                                destination: {
                                    zip_code: normalizedZipCode,
                                    address: address,
                                    name: name,
                                    phone_number: normalizedPhoneNumber,
                                    delivery_time: deliveryTime,
                                    delivery_locker: deliveryLocker
                                },
                                items: baseOrder.items.map((item, idx) => ({ kind: item.kind, product_code: item.product.code, product_unit_code: item.product_unit_code, amount: itemsAmount[idx] })),
                                remarks: remarks
                            };
                            setNewOrderEstimation(estimation);
                            setNewOrderData(newOrderData);
                            if (!account.masked_email) {
                                setNewEmail(email);
                            }
                            setPage("confirm");
                        }
                    }}
                />
            </Form>
        </Page>
    );
}

function SelfOrderFormConfirmPage({ account, dealer, baseOrder, newOrderData, newOrderEstimation, newEmail, setPage, onClose }) {
    return (
        <Page title="注文内容の確認" onRewind={() => setPage("order")} onReset={onClose}>
            <SelfOrderDealerHeader dealer={dealer} />
            <Segment>
                <Header as="h5">商品</Header>
                <Table>
                    <Table.Body>{
                        baseOrder.items.map((item, idx) => {
                            const amount = newOrderData.items[idx].amount;
                            return (
                                <Table.Row key={idx}>
                                    <Table.Cell width={2}>{formatOrderItemKindName(item.kind)}</Table.Cell>
                                    <Table.Cell width={5}>
                                        <h5>{item.product.name}</h5>
                                        <p>{formatLensData(item.bc, item.size, item.sph, item.cyl, item.ax, item.add)}</p>
                                    </Table.Cell>
                                    <Table.Cell width={3} textAlign="right">
                                        {(item.product.available) ? `${formatCurrency(item.product.price)}／箱` : "取扱終了"}
                                    </Table.Cell>
                                    <Table.Cell width={3} textAlign="right">
                                        <em>{`${amount}箱`}</em>
                                    </Table.Cell>
                                    <Table.Cell width={3} textAlign="right">
                                        {formatCurrency(item.product.price * amount)}
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })
                    }</Table.Body>
                </Table>
                <div style={{ textAlign: "right" }}>
                    <span style={{ padding: "0 1em" }}>送料： {formatCurrency(newOrderEstimation.postage_price)}</span>
                    <span style={{ padding: "0 1em", fontWeight: "bold" }}>合計： {formatCurrency(newOrderEstimation.total_price)}</span>
                </div>
            </Segment>
            <Segment>
                <Header as="h5">送付先</Header>
                <Table singleLine>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell width={4}>郵便番号</Table.Cell>
                            <Table.Cell width={12}>{newOrderData.destination.zip_code}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>住所</Table.Cell>
                            <Table.Cell>{newOrderData.destination.address}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>氏名</Table.Cell>
                            <Table.Cell>{newOrderData.destination.name}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>電話番号</Table.Cell>
                            <Table.Cell>{newOrderData.destination.phone_number}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>希望時間帯</Table.Cell>
                            <Table.Cell>{deliveryTimeOptions.find(x => x.value === newOrderData.destination.delivery_time)?.text ?? "--"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>宅配ボックス</Table.Cell>
                            <Table.Cell>{deliveryLockerOptions.find(x => x.value === newOrderData.destination.delivery_locker)?.text ?? "--"}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
            <Segment>
                <Header as="h5">お届け予定日の通知先</Header>
                <p>{account.masked_email ? account.masked_email : ((newEmail !== "") ? newEmail : "（通知しません）")}</p>
            </Segment>
            <Segment>
                <p>{newOrderData.remarks}</p>
            </Segment>

            <Button
                primary
                fluid
                content="お支払い"
                onClick={() => setPage("payment")}
            />
        </Page>
    );
}

function SelfOrderFormPaymentPage({ account, dealer, baseOrder, newOrderData, newOrderEstimation, newEmail, setPage, onClose }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        var apiAgent = ApiAgent(account.auth_token);

        window.setToken = (token, cardNumber) => {
            setLoading(true);
            apiAgent.send(
                'POST', '/claves/self_orders',
                { ...newOrderData, token: token, card_number: cardNumber },
                () => {
                    if (newEmail !== "") {
                        apiAgent.send(
                            'PUT', `/profile/notification`,
                            { email: newEmail },
                            () => {
                                setError("");
                                setLoading(false);
                                setPage("complete");
                            },
                            (e) => {
                                setError("注文は完了しましたが、通知先メールアドレスを登録できませんでした");
                                setLoading(false);
                                setPage("complete");
                            }
                        );
                    } else {
                        setError("");
                        setLoading(false);
                        setPage("complete");
                    }
                },
                (e) => {
                    setError(e.message);
                    setLoading(false);
                }
            );
        };

        return () => { window.setToken = undefined; };
    }, [account.auth_token, newOrderData, newEmail, setPage]);

    return (
        <Page title="お支払い" onRewind={() => setPage("confirm")} onReset={onClose}>
            <SelfOrderDealerHeader dealer={dealer} />
            <Message negative content={error} hidden={error === ""} />
            <Segment>
                <Table>
                    <Table.Body>
                        <Table.Row>
                            <Table.HeaderCell>お支払金額</Table.HeaderCell>
                            <Table.Cell textAlign="right">
                                {formatCurrency(newOrderEstimation.total_price)}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell>クレジットカード　対応ブランド</Table.HeaderCell>
                            <Table.Cell textAlign="right">
                                <img src={getApiPath("/claves/dealer/creditcard.png")} style={{ maxHeight: "1.5em" }} alt="クレジットカード 対応ブランド" />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>

            {(error === "") ?
                <Button
                    primary
                    fluid
                    content="クレジットカード決済で注文を確定する"
                    loading={loading}
                    onClick={() => {
                        window.SpsvApi.spsvCardInfoScreen();
                    }}
                />
                :
                <Button fluid onClick={() => window.location.reload()}>やり直し</Button>
            }
        </Page>
    );
}

function SelfOrderFormCompletePage({ dealer, onClose }) {
    useEffect(() => { window.scrollTo(0, 0) }, []);

    return (
        <Page title="注文完了">
            <SelfOrderDealerHeader dealer={dealer} />
            <p style={{ padding: "2em 0" }}>ご注文を承りました。商品到着まで今しばらくお待ちください。</p>
            <Button
                fluid
                primary
                content="トップページへ"
                onClick={onClose}
            />
        </Page>
    );
}

function SelfOrderForm({ account, dealer, baseOrder, onClose }) {
    const [page, setPage] = useState("welcome");   // welcome -> question -> order -> confirm -> complete
    const [remarks, setRemarks] = useState("");
    const [newOrderData, setNewOrderData] = useState({
        base_order_code: baseOrder.code,
        destination: {
            zip_code: "",
            address: "",
            name: "",
            phone_number: "",
            delivery_time: "any",
            delivery_locker: false
        },
        items: baseOrder.items.map(item => ({ kind: item.kind, product_code: item.product.code, product_unit_code: item.product_unit_code, amount: 0 })),
        remarks: remarks
    });
    const [newEmail, setNewEmail] = useState("");
    const [newOrderEstimation, setNewOrderEstimation] = useState();

    return (
        <div>
            {(page === "welcome") &&
                <SelfOrderFormWelcomePage
                    dealer={dealer}
                    setPage={setPage} onClose={onClose}
                />
            }
            {(page === "notice") &&
                <SelfOrderFormNoticePage
                    dealer={dealer}
                    setRemarks={setRemarks}
                    setPage={setPage} onClose={onClose}
                />
            }
            {(page === "question") &&
                <SelfOrderFormQuestionPage
                    dealer={dealer}
                    setRemarks={setRemarks}
                    setPage={setPage} onClose={onClose}
                />
            }
            {(page === "order") &&
                <SelfOrderFormOrderPage
                    account={account} dealer={dealer} baseOrder={baseOrder} newOrderData={newOrderData} newOrderEstimation={newOrderEstimation} newEmail={newEmail} remarks={remarks}
                    setNewOrderData={setNewOrderData} setNewOrderEstimation={setNewOrderEstimation} setNewEmail={setNewEmail}
                    setPage={setPage} onClose={onClose}
                />
            }
            {(page === "confirm") &&
                <SelfOrderFormConfirmPage
                    account={account} dealer={dealer} baseOrder={baseOrder} newOrderData={newOrderData} newOrderEstimation={newOrderEstimation} newEmail={newEmail} remarks={remarks}
                    setPage={setPage} onClose={onClose}
                />
            }
            {(page === "payment") &&
                <SelfOrderFormPaymentPage
                    account={account} dealer={dealer} baseOrder={baseOrder} newOrderData={newOrderData} newOrderEstimation={newOrderEstimation} newEmail={newEmail} remarks={remarks}
                    setPage={setPage} onClose={onClose}
                />
            }
            {(page === "complete") &&
                <SelfOrderFormCompletePage
                    dealer={dealer}
                    onClose={onClose}
                />
            }
        </div>
    );
}

export default SelfOrderForm;
