import { List, Checkbox ,Form, TextArea} from "semantic-ui-react";

const Question = ({ question, onValueChanged }) => {
  const inputAction = (value) => {

    const values = question.answer.values;
    if (question.answer.kind === "checkbox") {
      if (values.includes(value))
        onValueChanged(values.filter((v) => v !== value));
      else onValueChanged([...values, value]);
    } else {
      onValueChanged([value]);
    }
  };
  return (
    <div style={{ display: "flex", justifyContent: "center", margin: "1em 0" }}>
      <div style={{ maxWidth: "30em" }}>
        <h4>{question.title}</h4>
        <div style={{textAlign:"left"}}>
          {(question.answer.kind === "radio" || question.answer.kind === "checkbox") && (
            <List>
              {question.answer.options.map((op) => (
                <List.Item key={op.value}>
                  <List.Content>
                    <Checkbox
                      radio={question.answer.kind === "radio" }
                      label={op.text}
                      name={op.value}
                      checked={question.answer.values.includes(op.value)}
                      onChange={() => inputAction(op.value)}
                    />
                  </List.Content>
                </List.Item>
              ))}
            </List>
          )}
          {question.answer.kind === "text"
            && (question.answer.text_option === null || !question.answer.text_option.multiline) && (
            <Form size='large' style={{ textAlign: "center" }}>
              <Form.Input
                placeholder={question.answer.description}
                value={question.answer.values[0]} onChange={(e) => inputAction(e.target.value)} />
            </Form>
            )}
          {question.answer.kind === "text"
            && (question.answer.text_option != null && question.answer.text_option.multiline) && (
              <Form size='large' style={{ textAlign: "center" }}>
                <TextArea
                  placeholder={question.answer.description}
                  value={question.answer.values[0]} onChange={(e) => inputAction(e.target.value)} />
              </Form>
          )}
        </div>
 
      </div>
    </div>
  );
};

export default Question;
