import React, { useState, useEffect } from 'react'
import ApiAgent from './ApiAgent';
import Page from './Page';
import SelfOrderView from './SelfOrderView';
import { Message, Dimmer, Loader } from 'semantic-ui-react'
import SelfOrderDealerHeader from './SelfOrderDealerHeader';


function SelfOrderHistory({ account, dealer, onClose }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [selfOrders, setSelfOrders] = useState([]);

    useEffect(() => {
        ApiAgent(account.auth_token).send(
            'GET', '/claves/self_orders', null,
            (list) => {
                setError("");
                setSelfOrders(list);
                setLoading(false);
            },
            (e) => {
                setError(e.message);
                setLoading(false);
            }
        );
    }, [account.auth_token]);

    return (
        <Page title="注文履歴" onRewind={onClose}>
            <Dimmer active={loading} inverted><Loader inverted>通信中..</Loader></Dimmer>
            <Message negative content={error} hidden={error === ""} />
            <SelfOrderDealerHeader dealer={dealer} />
            <div>{
                selfOrders.map((selfOrder) => {
                    return (
                        <SelfOrderView
                            key={selfOrder.code}
                            selfOrder={selfOrder}
                        />
                    )
                })
            }</div>
        </Page>
    );
}

export default SelfOrderHistory;
