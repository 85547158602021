import React, { useState, useEffect } from 'react'
import { Header, Image, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'
import queryString from 'query-string';
import NaviMenu from './NaviMenu';
import ProspectNavi from './ProspectNavi';
import PatientNavi from './PatientNavi';
import LoginForm from './LoginForm';
import MobileAppBanner from './MobileAppBanner';
import ApiAgent from './ApiAgent';
import { Helmet } from 'react-helmet-async';

function App() {
  const [error, setError] = useState("");
  const [page, setPage] = useState("top"); // top, patient, prospect
  const [account, setAccount] = useState(null);
  const [tenant, setTenant] = useState({ code: "", name: "", main_menu: { prospect: "", patient: "" }, menus: [], links: [], remarks: "", escott_script_url: null, ios_app_id: null, android_app_id: null, capabilities: { app: false, sms: false, claves_self_order: false } });
  const [loading, setLoading] = useState(false);
  const [selectdProspectItem, setSelectedProspectItem] = useState(null);

  const qs = queryString.parse(window.location.search);

  const getTenant = () => {
    setLoading(true);
    ApiAgent(null).send(
      "GET", "/tenant", null,
      (x) => {
        setError("");
        setTenant(x);
        setLoading(false);
      },
      (e) => {
        setError(e.message);
        setLoading(false);
      }
    );
  };

  const logout = () => {
    if (account !== null) {
      setLoading(true);
      ApiAgent(account.auth_token).send(
        "DELETE", "/token", null,
        () => {
          setError("");
          setAccount(null);
          setLoading(false);
        },
        (e) => {
          setError(e.message);
          setLoading(false);
        }
      );
    }
  };

  const prospectMainMenu = tenant.menus.find(x => x.code === tenant.main_menu.prospect);

  useEffect(getTenant, []);
  useEffect(() => { window.scrollTo(0, 0) }, [error]);
  useEffect(() => { if (account) window.localStorage.setItem('code', account.code); }, [account]);

  return (
    <div>
      <Dimmer active={loading} inverted><Loader inverted>通信中..</Loader></Dimmer>
      <Helmet>
        <title>{`${tenant.name} Web予約`}</title>
        {tenant.escott_script_url &&
          <script type="text/javascript" src={tenant.escott_script_url} callBackFunc="setToken" class="spsvToken"></script>
        }
      </Helmet>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0.5em" }}>
        <a href={tenant.home_page}>
          <Image src='logo.png' style={{ height: "2em" }} />
        </a>
        <span>Web予約</span>
      </div>
      {page === "top" &&
        <div style={{ padding: "0.5em" }}>
          <Message negative content={error} hidden={error === ""} />
          <div>
            <div>
              {tenant.capabilities.app &&
                <Message positive>
                  <Message.Header>
                    <Image src='app-icon.png' width="48" style={{ marginRight: "0.5em" }} verticalAlign='middle' />
                    {`スマートフォンをお持ちの方は、${tenant.name}公式アプリをご利用ください`}
                  </Message.Header>
                  <MobileAppBanner tenant={tenant} />
                </Message>
              }
              <Segment color="blue">
                <Header size='small'>ログイン<span style={{ fontSize: "small" }}>（当院の受診歴がある方・患者登録済の方）</span></Header>
                <LoginForm
                  defaultCode={qs.code ? qs.code : (window.localStorage.getItem('code') ? window.localStorage.getItem('code') : "")}
                  onLogin={(a) => {
                    setError("");
                    setAccount(a);
                    setPage("patient");
                  }}
                  onError={setError}
                />
              </Segment>
              <Segment color="orange">
                <Header size='small'>利用者登録して予約<span style={{ fontSize: "small" }}>（当院の受診がはじめてで、患者登録していない方）</span></Header>
                {prospectMainMenu &&
                  <NaviMenu
                    tenant={tenant}
                    menu={prospectMainMenu}
                    onSelectChildMenu={(menu) => {
                      setSelectedProspectItem({ kind: "menu", code: menu.code })
                      setPage("prospect")
                    }}
                    onSelectLink={(link) => {
                      setSelectedProspectItem({ kind: "link", code: link.code })
                      setPage("prospect")
                    }}
                  ></NaviMenu>
                }
              </Segment>
            </div>
            <div style={{ margin: "3em 0" }}>
              <div dangerouslySetInnerHTML={{ __html: tenant.remarks }} />
            </div>
          </div>
        </div>
      }
      {page === "prospect" &&
        <ProspectNavi
          tenant={tenant}
          item={selectdProspectItem}
          onClose={() => { logout(); setPage("top"); }}
          onCommit={(a) => { setError(""); setAccount(a); setPage("patient"); }} />
      }
      {page === "patient" &&
        <PatientNavi
          tenant={tenant}
          defaultAccount={account}
          onClose={() => { logout(); setPage("top"); }}
        />
      }
    </div>
  );
}

export default App;
