import { Image } from 'semantic-ui-react'

function MobileAppBanner({ tenant }) {
  return (
    <div style={{ display: "flex", justifyContent: "center", padding: "0.5em" }}>
      {tenant.android_app_id &&
        <a href={`https://play.google.com/store/apps/details?id=${tenant.android_app_id}`}>
          <Image src='google-play-badge.png' size="small" style={{ marginRight: "0.5em" }} alt='Google Play で手に入れよう' />
        </a>
      }
      {tenant.ios_app_id &&
        <a href={`https://apps.apple.com/jp/app/id${tenant.ios_app_id}`}>
          <Image src='app-store-badge.png' size="small" alt='App Store からダウンロード' />
        </a>
      }
    </div>
  );
}

export default MobileAppBanner