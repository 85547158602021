import { getApiPath } from './Utils';
import queryString from 'query-string';

function ApiAgent(token) {
    return {
        send: (method, path, json, responseHandler, errorHandler) => {
            let parameters = null;
            let headers = new Headers();
            if (token) {
                headers.append('Authorization', `Bearer ${token}`);
            }
            let body = null;
            if (json != null) {
                if (method === "GET") {
                    parameters = queryString.stringify(json, {skipNull: true});
                } else {
                    headers.append('Content-Type', `application/json`);
                    body = JSON.stringify(json);
                }
            }
            fetch(getApiPath(parameters ? path + "?" + parameters : path), { method: method, headers: headers, body: body })
                .catch(error => Promise.reject({status: "", message: "システムに接続できませんでした。少し時間を置いてから、もう一度操作してください。"}))
                .then(res => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 204) {
                        return Promise.resolve(null);
                    } else if (res.status === 400) {
                        return res.text().then(r => Promise.reject({status: res.status, message: r}));
                    } else if (res.status === 401) {
                        return Promise.reject({status: res.status, message: "一定時間が経過したため、処理を続行できません。はじめからやり直してください。"});
                    } else {
                        return Promise.reject({status: res.status, message: "システムエラーにより、処理を完了できませんでした。"});
                    }
                })
                .then(responseHandler, errorHandler);
        }
    }
}

export default ApiAgent