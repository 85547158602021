import React, { useState, useEffect } from 'react'
import { Segment, Header, Button, Form, Message, Dimmer, Loader, Dropdown } from 'semantic-ui-react'
import { format, parse } from 'date-fns';
import LoginForm from './LoginForm';
import Page from './Page';
import ApiAgent from './ApiAgent'
import { checkEmail, checkSms, checkName, checkNameKana, checkPhoneNumber, convertToHankakuAlphaNumeric } from './Utils'

function ProspectForm({ tenant, onCommit, onClose }) {
    const [error, setError] = useState("");
    // request -> verify -> registration
    // request
    const [page, setPage] = useState("request");
    const [useSms, setUseSms] = useState(false);
    const [email, setEmail] = useState("");
    const [sms, setSms] = useState("");
    const [passcode, setPasscode] = useState("");
    const [realFamilyName, setRealFamilyName] = useState("");
    const [realFamilyNameKana, setRealFamilyNameKana] = useState("");
    const [realFirstName, setRealFirstName] = useState("");
    const [realFirstNameKana, setRealFirstNameKana] = useState("");
    const [sex, setSex] = useState("");
    const [birthdayYear, setBirthdayYear] = useState(" ");
    const [birthdayMonth, setBirthdayMonth] = useState(" ");
    const [birthdayDay, setBirthdayDay] = useState(" ");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [loading, setLoading] = useState(false);

    const currentYear = new Date().getFullYear()

    const checkPasscode = () => {
        const re = /^[0-9]{6}$/;
        return re.test(passcode);
    };

    const checkBirthday = () => {
        try {
            const d = parse(`${birthdayYear}-${birthdayMonth}-${birthdayDay}`, "yyyy-M-d", new Date());
            return isNaN(d) ? null : d;
        } catch (e) {
            return null;
        }
    };

    const getBirthday = checkBirthday;

    const checkAndRegister = () => {
        const normalizedRealFamilyName = realFamilyName.trim();
        const normalizedRrealFirstName = realFirstName.trim();
        const normalizedRealFamilyNameKana = realFamilyNameKana.trim();
        const normalizedRealFirstNameKana = realFirstNameKana.trim();
        const normalizedPhoneNumber = convertToHankakuAlphaNumeric(phoneNumber).replace(/[^0-9]/g, '');

        if (normalizedRealFamilyName === "") {
            setError("「氏名（姓）」を入力してください");
        } else if (normalizedRrealFirstName === "") {
            setError("「氏名（名）」を入力してください");
        } else if (normalizedRealFamilyNameKana === "") {
            setError("「フリガナ（セイ）」を入力してください");
        } else if (normalizedRealFirstNameKana === "") {
            setError("「フリガナ（メイ）」を入力してください");
        } else if (birthdayYear === " " || birthdayMonth === " " || birthdayDay === " ") {
            setError("「生年月日」を選択してください");
        } else if (normalizedPhoneNumber === "") {
            setError("「電話番号」を入力してください");
        } else {
            if (!checkName(normalizedRealFamilyName) || !checkName(normalizedRrealFirstName)) {
                setError("「氏名」は全角文字で入力してください");
            } else if (!checkNameKana(normalizedRealFamilyNameKana) || !checkNameKana(normalizedRealFirstNameKana)) {
                setError("「フリガナ」は全角カタカナで入力してください");
            } else if (!checkBirthday()) {
                setError("「生年月日」の日付が正しくありません");
            } else if (!checkPhoneNumber(normalizedPhoneNumber)) {
                setError("「電話番号」は、市外局番から入力してください");
            } else {
                setError("");
                setLoading(true);
                ApiAgent(null).send(
                    'POST', '/prospect',
                    {
                        passcode: passcode,
                        real_name: normalizedRealFamilyName + "　" + normalizedRrealFirstName,
                        real_name_kana: normalizedRealFamilyNameKana + "　" + normalizedRealFirstNameKana,
                        sex: sex,
                        birthday: format(getBirthday(), "yyyy-MM-dd"),
                        phone_number: normalizedPhoneNumber
                    },
                    (x) => {
                        onCommit(x);
                        setLoading(false);
                    },
                    (e) => {
                        setError(e.message);
                        setLoading(false);
                    }
                );
            }
        }
    }

    const requestWithSms = () => {
        setLoading(true);
        ApiAgent(null).send(
            'POST', '/passcode/sms', { sms: sms },
            () => {
                setError("");
                setPage("verify");
                setLoading(false);
            },
            (e) => {
                setError(e.message);
                setLoading(false);
            }
        );
    };

    const requestWithEmail = () => {
        setLoading(true);
        ApiAgent(null).send(
            'POST', '/passcode/email', { email: email },
            () => {
                setError("");
                setPage("verify");
                setLoading(false);
            },
            (e) => {
                setError(e.message);
                setLoading(false);
            }
        );
    };

    const validate = () => {
        setLoading(true);
        ApiAgent(null).send(
            'POST', '/passcode', { passcode: passcode },
            () => {
                setError("");
                setPage("registration");
                setLoading(false);
            },
            (e) => {
                setError(e.message);
                setLoading(false);
            }
        );
    };

    useEffect(() => { window.scrollTo(0, 0) }, [error])

    return (
        <div>
            <Dimmer active={loading} inverted><Loader inverted>通信中..</Loader></Dimmer>
            <Message negative content={error} hidden={error === ""} />
            {page === "request" &&
                <Page title="患者登録・ログイン" onReset={onClose}>
                    <Segment>
                        <Header size='small'>患者登録</Header>
                        <p>認証コードの受信先を入力してください</p>
                        <Form>
                            <Form.Group inline>
                                <Form.Radio
                                    label='メールアドレス'
                                    checked={!useSms}
                                    onChange={() => setUseSms(false)}
                                />
                                {tenant.capabilities.sms &&
                                    <Form.Radio
                                        label='携帯番号（SMS）'
                                        checked={useSms}
                                        onChange={() => setUseSms(true)}
                                    />
                                }
                            </Form.Group>
                            {useSms ?
                                <div>
                                    <Form.Input required value={sms} placeholder='09012345678' onChange={(e) => { setSms(e.target.value); setPhoneNumber(e.target.value); }}>
                                        <input type="tel" />
                                    </Form.Input>
                                    <div style={{ textAlign: "center", margin: "1em 0" }} >
                                        <Button positive disabled={!checkSms(sms)} onClick={() => requestWithSms()}>認証コードを受け取る</Button>
                                    </div>
                                </div>
                                :
                                <div>
                                    <Form.Input required value={email} placeholder='sample@foo.bar' onChange={(e) => setEmail(e.target.value)}>
                                        <input type="email" />
                                    </Form.Input>
                                    <div style={{ textAlign: "center", margin: "1em 0" }} >
                                        <Button positive disabled={!checkEmail(email)} onClick={() => requestWithEmail()}>認証コードを受け取る</Button>
                                    </div>
                                </div>
                            }
                        </Form>
                    </Segment>
                    <Segment>
                        <Header size='small'>ログイン</Header>
                        <p>当院の受診歴がある方・患者登録済の方は、ログインしてください</p>
                        <LoginForm
                            defaultCode=""
                            onLogin={(a) => {
                                setError("");
                                onCommit(a);
                            }}
                            onError={setError}
                        />
                    </Segment>
                </Page>
            }
            {page === "verify" &&
                <Page title="認証コード確認" onRewind={() => setPage("request")} onReset={onClose}>
                    <p>{useSms ? sms : email} に送信された認証コードを入力してください</p>
                    <Form>
                        <Form.Input label='認証コード' required value={passcode} onChange={(e) => setPasscode(e.target.value)} />
                    </Form>
                    <div style={{ textAlign: "center", margin: "1em 0" }} >
                        <Button positive disabled={!checkPasscode()} onClick={() => validate()}>次へ進む</Button>
                    </div>
                </Page>
            }
            {page === "registration" &&
                <Page title="個人情報入力" onReset={onClose}>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Input label='氏名（姓）' placeholder='佐藤' value={realFamilyName} onChange={(e) => setRealFamilyName(e.target.value)} />
                            <Form.Input label='氏名（名）' placeholder='涼子' value={realFirstName} onChange={(e) => setRealFirstName(e.target.value)} />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input label='フリガナ（セイ）' placeholder='サトウ' value={realFamilyNameKana} onChange={(e) => setRealFamilyNameKana(e.target.value)} />
                            <Form.Input label='フリガナ（メイ）' placeholder='リョウコ' value={realFirstNameKana} onChange={(e) => setRealFirstNameKana(e.target.value)} />
                        </Form.Group>
                        <div className='field'>
                            <label>性別</label>
                        </div>
                        <Form.Group inline>
                            <Form.Radio
                                label='男性'
                                checked={sex === "男"}
                                onChange={(e, { value }) => setSex("男")}
                            />
                            <Form.Radio
                                label='女性'
                                checked={sex === "女"}
                                onChange={(e, { value }) => setSex("女")}
                            />
                            <Form.Radio
                                label='（未選択）'
                                checked={sex === ""}
                                onChange={(e, { value }) => setSex("")}
                            />
                        </Form.Group>

                        <div className='field'>
                            <label>生年月日</label>
                        </div>
                        <Form.Group>
                            <Dropdown selection compact
                                value={birthdayYear}
                                options={[...Array(120).keys()].map(n => (n === 0) ? ({ key: n, value: ` `, text: `　　　　年` }) : ({ key: n, value: `${currentYear + 1 - n}`, text: `${currentYear + 1 - n} 年` }))}
                                onChange={(e, { value }) => { setBirthdayYear(value); }}
                            />
                            <Dropdown selection compact
                                value={birthdayMonth}
                                options={[...Array(13).keys()].map(n => (n === 0) ? ({ key: n, value: ` `, text: `　　月` }) : ({ key: n, value: `${n}`, text: `${n} 月` }))}
                                onChange={(e, { value }) => { setBirthdayMonth(value); }}
                            />
                            <Dropdown selection compact
                                value={birthdayDay}
                                options={[...Array(32).keys()].map(n => (n === 0) ? ({ key: n, value: ` `, text: `　　日` }) : ({ key: n, value: `${n}`, text: `${n} 日` }))}
                                onChange={(e, { value }) => { setBirthdayDay(value); }}
                            />
                        </Form.Group>
                        <Form.Input label='電話番号' placeholder='09012345678' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}>
                            <input type="tel" />
                        </Form.Input>
                        <div style={{ textAlign: "center", margin: "1em 0" }} >
                            <Button positive onClick={() => checkAndRegister()}>登録</Button>
                        </div>
                    </Form>
                </Page>
            }
        </div>
    );
}

export default ProspectForm;
